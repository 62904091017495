<template>
  <div class="editor-item">
    <editor-header
      :title="title"
      :helperReference="contentData.page_block_id"
      :hasHelper="contentData.has_helper"
    />
    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <draggable-item
        v-model="contentData.items"
        :group="'subitems' + contentData.id"
        animation="300"
        handle=".sub__drag"
        @start="dragging = true"
        @end="dragging = false"
      >
        <div
          v-for="(item, index) in contentData.items"
          :key="item.number"
          :toggle="index"
          :dragStatus="dragging"
          :class="'editor-item__sub-container'"
        >
          <div v-if="item.type === 'text'">
            <text-card
              :item="item"
              :index="index"
              :dragStatus="dragging"
              v-on:deleteItem="deleteItem"
            ></text-card>
          </div>
          <div v-else-if="item.type === 'text-text'">
            <text-text-card
              :item="item"
              :index="index"
              :dragStatus="dragging"
              v-on:deleteItem="deleteItem"
            ></text-text-card>
          </div>
          <div v-else-if="item.type === 'text-text-text'">
            <text-text-text-card
              :item="item"
              :index="index"
              :dragStatus="dragging"
              v-on:deleteItem="deleteItem"
            ></text-text-text-card>
          </div>

          <div v-else-if="item.type === 'text-image'">
            <text-image-card
              :item="item"
              :index="index"
              :dragStatus="dragging"
              v-on:deleteItem="deleteItem"
            ></text-image-card>
          </div>

          <div v-else-if="item.type === 'image-text'">
            <image-text-card
              :item="item"
              :index="index"
              :dragStatus="dragging"
              v-on:deleteItem="deleteItem"
            ></image-text-card>
          </div>
          <div v-else class="editor-item__sub-content">
            <h2>Not defined</h2>
          </div>
        </div>
      </draggable-item>
      <hr v-if="contentData.items.length > 0"/>
      <add-brand-data-button
        :title="$t('builder.add_item')"
        @add-item="addItem"
      />
    </div>
  </div>
</template>

<script>
import textCard from './CardContentRepeaterItems/TextCard'
import textTextCard from './CardContentRepeaterItems/TextTextCard'
import textTextTextCard from './CardContentRepeaterItems/TextTextTextCard'
import textImageCard from './CardContentRepeaterItems/TextImageCard'
import imageTextCard from './CardContentRepeaterItems/ImageTextCard'
import addContentRepeaterData from './../elements/modal/addContentRepeaterData'

export default {
  name: 'CardContentRepeater',
  props: {
    contentItem: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      isVisible: true
    }
  },
  created () {
    for (let i = 0; i < this.contentData.items.length; i++) {
      this.contentData.items[i].number = i
    }
  },
  methods: {
    addItem () {
      this.$modal.show(
        addContentRepeaterData,
        {
          contentItem: this.contentData
        },
        {
          draggable: false,
          scrollable: true,
          height: 'auto',
          width: '860px'
        }
      )
    },
    deleteItem: function (index) {
      this.contentData.items.splice(index, 1)
    }
  },
  computed: {
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    title: function () {
      if (this.contentData._title) {
        return this.contentData._title
      }
      return this.$t('builder.text_blocks')
    },
    dragging: {
      get () {
        return this.dragStatus
      },
      set (val) {
        this.$emit('update:dragStatus', val)
      }
    }
  },
  components: {
    textCard,
    textTextCard,
    textTextTextCard,
    textImageCard,
    imageTextCard
  }
}
</script>

<style lang="scss" scoped></style>
