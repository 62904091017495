<template>
  <div class="builder-modal">
    <tab-list
      tabItems="builder-tab"
      tabItem="builder-tab__item"
      tabHeaderContainer="builder-modal__header"
      tabContentContainer="builder-modal__content"
    >
      <tab-item :name="$t('builder.content')" :selected="true">
        <brand-data-item
          image="/assets/images/editor/content-repeater/add_one_column.jpg"
          :title="$t('builder.text')"
          :explanation="$t('builder.text_explanation')"
          @add-item="addText(contentData.id)"
        />
        <brand-data-item
          image="/assets/images/editor/content-repeater/add_two_columns.jpg"
          :title="$t('builder.text_text')"
          :explanation="$t('builder.text_text_explanation')"
          @add-item="addTextText(contentData.id)"
        />
        <brand-data-item
          image="/assets/images/editor/content-repeater/add_three_columns.jpg"
          :title="$t('builder.text_text_text')"
          :explanation="$t('builder.text_text_text_explanation')"
          @add-item="addTextTextText(contentData.id)"
        />
        <brand-data-item
          image="/assets/images/editor/content-repeater/add_text_image.jpg"
          :title="$t('builder.text_image')"
          :explanation="$t('builder.text_image_explanation')"
          @add-item="addTextImage(contentData.id)"
        />
        <brand-data-item
          image="/assets/images/editor/content-repeater/add_image_text.jpg"
          :title="$t('builder.image_text')"
          :explanation="$t('builder.image_text_explanation')"
          @add-item="addImageText(contentData.id)"
        />
      </tab-item>
    </tab-list>
  </div>
</template>

<script>
import BrandDataItem from './BrandDataItem'
import TabList from '@/views/common/components/tabs/TabList'
import TabItem from '@/views/common/components/tabs/TabItem'

export default {
  name: 'addContentRepeaterData',
  props: {
    contentItem: Object
  },
  methods: {
    addText () {
      this.contentData.items.push({
        type: 'text',
        columns: '1-1',
        text: '',
        image: '',
        imagePadding: '0',
        number: this.contentData.items.length
      })
      this.$emit('close')
    },
    addTextText () {
      this.contentData.items.push({
        type: 'text-text',
        columns: '1-1',
        text: '',
        text_col_two: '',
        image: '',
        imagePadding: '0',
        number: this.contentData.items.length
      })
      this.$emit('close')
    },
    addTextTextText () {
      this.contentData.items.push({
        type: 'text-text-text',
        columns: '1-1',
        text: '',
        text_col_two: '',
        text_col_three: '',
        image: '',
        imagePadding: '0',
        number: this.contentData.items.length
      })
      this.$emit('close')
    },
    addTextImage () {
      this.contentData.items.push({
        type: 'text-image',
        columns: '1-1',
        text: '',
        text_col_two: '',
        text_col_three: '',
        image: '',
        imagePadding: '1',
        number: this.contentData.items.length
      })
      this.$emit('close')
    },
    addImageText () {
      this.contentData.items.push({
        type: 'image-text',
        columns: '1-1',
        text: '',
        text_col_two: '',
        text_col_three: '',
        image: '',
        imagePadding: '1',
        number: this.contentData.items.length
      })
      this.$emit('close')
    }
  },
  computed: {
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  components: {
    BrandDataItem,
    TabList,
    TabItem
  }
}
</script>

<style lang="scss" scoped>
input {
  width: 100%;
}

textarea {
  padding: 0;
  height: auto;
  resize: none;
}

hr {
  margin: 0;
  border-color: $ui-line-color;
}

.card {
  overflow: visible;
}

.card__button {
  padding: 0;
  margin: 0;

  i {
    opacity: 1;
  }

  &:hover {
    background: none;
  }

  &::after {
    background: transparent;
  }
}

.editor-card-sort {
  margin: 0 0 10px;
  padding-right: 0;

  li {
    margin-top: 0;
  }
}

.editor-item__sub-item {
  border: 0;
  border-top: 2px solid $ui-line-color;
}
</style>
