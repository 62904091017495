<template>
  <div class="editor-item">
    <editor-header
      :title="title"
      :hasHelper="contentData.has_helper"
      :helperReference="contentData.page_block_id"
    />
    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <card-item>
        <div class="chapter-title">
          <input
            :placeholder="$t('builder.type_your_title')"
            v-model="contentData.title"
          />
        </div>
      </card-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChapterTitle',
  props: ['contentItem'],
  data () {
    return {
      isVisible: true
    }
  },
  computed: {
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    title: function () {
      if (this.contentData._title) {
        return this.contentData._title
      }
      return this.$t('builder.title')
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  font-size: 26px;
  font-weight: 300;
  color: #454545;
  padding: 0;
  background: transparent;
  height: auto;
  width: 100%;
  border: none;

  &:focus {
    background: transparent;
  }
}
</style>
