<template>
  <div class="editor-item">
    <editor-header
      :showSettings="false"
      :title="$t('builder.related_assets')"
    />

    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <card-item>
        <ab-input-field
          :label="$t('app.title')"
          icon="icon-examples"
          v-model="contentData.title"
        />
      </card-item>
      <draggable-item
        v-model="contentData.items"
        :group="'subitems' + contentData.id"
        animation="300"
        handle=".sub__drag"
        @start="dragging = true"
        @end="dragging = false"
      >
        <div v-for="(item, index) in contentData.items" :key="index">
          <div class="editor-item__sub-item">
            <div class="editor-item__sub-header">
              <div class="grid-x align-right">
                <div class="cell shrink">
                  <delete-card v-on:confirmDelete="deleteItem(index)"/>
                  <span class="sub__drag editor-item__button"
                  ><span class="sub__drag"
                  ><i class="icon-drag-and-drop"/></span
                  ></span>
                </div>
              </div>
            </div>

            <card-item>
              <div v-if="item.title" class="uploaded-asset">
                {{ item.title }}
                <i @click="clearItem(item)" class="icon-icon-trash"/>
              </div>
              <span
                v-else
                class=""
                @click="show(item, id, index)"
              >
                  <div class="choose-asset">
                  {{ $t('builder.choose_asset') }}</div>
                </span>
            </card-item>
          </div>
        </div>
      </draggable-item>
      <hr/>
      <add-brand-data-button
        @add-item="addAsset"
        :title="$t('builder.add_asset')"
      />
    </div>
  </div>
</template>

<script>
import sidebarRelatedAssets from './modal/SidebarRelatedAssets'

export default {
  name: 'CardRelatedAssets',
  props: {
    contentItem: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      isVisible: true,
      id: null
    }
  },
  mounted () {
    this.id = this._uid
  },
  methods: {
    addAsset () {
      this.contentData.items.push({
        title: '',
        slug: ''
      })
    },
    show (item, id, index) {
      this.$modal.show(
        sidebarRelatedAssets,
        {
          item: item,
          id: id,
          index: index
        },
        {
          draggable: false,
          scrollable: true,
          height: 'auto',
          width: '1000px'
        }
      )
    },
    clearItem (item) {
      item.title = ''
    },
    hide (id, index) {
      this.$modal.hide('modal' + id + index)
    },
    deleteItem: function (index) {
      this.contentData.items.splice(index, 1)
    }
  },
  computed: {
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    dragging: {
      get () {
        return this.dragStatus
      },
      set (val) {
        this.$emit('update:dragStatus', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  width: 100%;
}

textarea {
  padding: 0;
  height: auto;
  resize: none;
}

hr {
  margin: 0;
  border-color: $ui-line-color;
}

.card {
  overflow: visible;
}

.card__button {
  padding: 0;
  margin: 0;

  i {
    opacity: 1;
  }

  &:hover {
    background: none;
  }

  &::after {
    background: transparent;
  }
}

.editor-card-sort {
  margin: 0 0 10px;
  padding-right: 0;

  li {
    margin-top: 0;
  }
}

.editor-item__sub-item {
  border: 0;
  border-top: 2px solid $ui-line-color;
}

.choose-asset {
  border: dashed 2px $ui-line-color;
  border-radius: $global-radius;
  width: 100%;
  padding: 10px 10px 10px 20px;

  &:hover {
    border: dashed 2px $black;
    cursor: pointer;
  }
}

.uploaded-asset {
  text-align: left;
  width: 100%;
  border: 2px solid $ui-line-color;
  border-radius: 5px;
  background: white;
  height: 4.4375rem;
  min-height: 4.4375rem;
  padding: 25px 8px 24px 59px;
  position: relative;
  font-size: 0.875rem;
  color: #454545;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;

  &::before {
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    content: $icon-file;
    font-family: $abrnd;
    color: #454545;
    left: 13px;
    font-size: 32px;
  }

  &::after {
    position: absolute;
    right: 0;
    width: 100%;
    top: 0;
    height: 100%;
    content: "";
    background: linear-gradient(to right, rgba(#fff, 0) 60%, rgba(#fff, 1) 80%);
  }

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    z-index: 5;
    cursor: pointer;
  }
}
</style>
