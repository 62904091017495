<template>
  <div class="editor-item">
    <editor-header :showSettings="false" :title="$t('app.literature')"/>

    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <card-item>
        <ab-input-field
          :label="$t('app.title')"
          icon="icon-literature"
          v-model="contentData.title"
        />
      </card-item>
      <draggable-item
        v-model="contentData.items"
        :group="'subitems' + contentData.id"
        animation="300"
        handle=".sub__drag"
        @start="dragging = true"
        @end="dragging = false"
      >
        <div v-for="(item, index) in contentData.items" :key="index">
          <div class="editor-item__sub-item">
            <div class="editor-item__sub-header">
              <div class="grid-x align-right">
                <div class="cell shrink">
                  <delete-card v-on:confirmDelete="deleteItem(index)"/>
                  <span class="sub__drag editor-item__button"
                  ><span class="sub__drag"
                  ><i class="icon-drag-and-drop"/></span
                  ></span>
                </div>
              </div>
            </div>
            <card-item>
              <ab-input-field :label="$t('app.title')" v-model="item.title"/>
              <ab-select
                :label="$t('app.type')"
                :options="typeOptions"
                v-model="item.type"
              />
              <ab-input-field
                v-if="item.type == 'link'"
                :label="$t('app.link')"
                v-model="item.link"
              />
              <div
                class="dropzone-single-file__container"
                v-if="item.type == 'file'"
              >
                <ab-dropzone
                  :singleFileUpload="true"
                  type="file"
                  :data="item"
                  :id="index"
                  :relation="'page'"
                />
              </div>
            </card-item>
          </div>
        </div>
      </draggable-item>
      <hr/>
      <add-brand-data-button
        @add-item="addLiterature"
        :title="$t('builder.add_literature')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'cardLiteratureList',
  props: {
    index: Number,
    contentItem: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      isVisible: true,
      id: null,
      typeOption: 'typeOption',
      typeOptions: [
        { title: this.$t('app.link'), value: 'link' },
        { title: this.$t('app.file'), value: 'file' }
      ]
    }
  },
  mounted () {
    this.id = this._uid
  },
  methods: {
    addLiterature () {
      this.contentData.items.push({
        title: '',
        image: '',
        text: '',
        link: '',
        type: null
      })
    },
    deleteItem: function (index) {
      this.contentData.items.splice(index, 1)
    }
  },
  computed: {
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    dragging: {
      get () {
        return this.dragStatus
      },
      set (val) {
        this.$emit('update:dragStatus', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  width: 100%;
}

textarea {
  padding: 0;
  height: auto;
  resize: none;
}

hr {
  margin: 0;
  border-color: $ui-line-color;
}

.card {
  overflow: visible;
}

.card__button {
  padding: 0;
  margin: 0;

  i {
    opacity: 1;
  }

  &:hover {
    background: none;
  }

  &::after {
    background: transparent;
  }
}

.editor-card-sort {
  margin: 0 0 10px;
  padding-right: 0;

  li {
    margin-top: 0;
  }
}

.editor-item__sub-item {
  border: 0;
  border-top: 2px solid $ui-line-color;
}
</style>
