var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editor-item"},[_c('editor-header',{attrs:{"item":_vm.contentData,"title":_vm.title,"helperReference":_vm.contentData.page_block_id,"hasHelper":_vm.contentData.has_helper}},[_c('template',{slot:"settings"},[_c('dropdown-item',{ref:"dropdown",class:'editor-item__settings editor-item__button',attrs:{"class-name":'custom',"isIcon":false,"unscroll":'main',"align":'left',"y":27,"x":27}},[_c('template',{slot:"btn"},[_c('i',{staticClass:"icon-menu-settings"})]),_c('template',{slot:"body"},[_c('div',{staticClass:"dropdown-edit"},[_c('card-item',[_c('ab-select',{attrs:{"label":_vm.$t('builder.choose_size'),"options":_vm.sizeOptions},model:{value:(_vm.contentData.size),callback:function ($$v) {_vm.$set(_vm.contentData, "size", $$v)},expression:"contentData.size"}}),_c('ab-select',{attrs:{"label":_vm.$t('builder.choose_background_type'),"options":_vm.typeOptions},model:{value:(_vm.contentData.type),callback:function ($$v) {_vm.$set(_vm.contentData, "type", $$v)},expression:"contentData.type"}}),(_vm.contentData.type === 'color')?_c('ab-color-picker',{attrs:{"hex":_vm.contentData.hex,"value":_vm.contentData.color,"label":_vm.$t('builder.choose_background_color'),"type":"large"},on:{"update:hex":function($event){return _vm.$set(_vm.contentData, "hex", $event)},"update:value":function($event){return _vm.$set(_vm.contentData, "color", $event)}}}):_vm._e(),_c('ab-select',{attrs:{"label":_vm.$t('builder.choose_alignment'),"options":_vm.alignmentOptions},model:{value:(_vm.contentData.alignment),callback:function ($$v) {_vm.$set(_vm.contentData, "alignment", $$v)},expression:"contentData.alignment"}})],1)],1)])],2)],1)],2),_c('div',{staticClass:"editor-item__content",class:[_vm.isVisible ? '' : 'hide']},[_c('div',{staticClass:"page-banner background--center",class:[_vm.contentData.size],style:([
        _vm.contentData.type === 'image'
          ? {
              backgroundImage: 'url(' + _vm.contentData.image + ')'
            }
          : { backgroundColor: '#' + _vm.contentData.color }
      ])},[_c('div',{staticClass:"page-banner__content"},[_c('div',{staticClass:"grid-x align-middle",class:[_vm.contentData.alignment === 'right' ? 'align-right' : '']},[_c('div',{staticClass:"cell content",class:[
              _vm.contentData.alignment === 'fullWidth' ? 'auto' : 'medium-6'
            ]},[_c('content-editor',{attrs:{"disabled":_vm.dragStatus,"contentData":_vm.contentData.text},on:{"update:contentData":function($event){return _vm.$set(_vm.contentData, "text", $event)},"update:content-data":function($event){return _vm.$set(_vm.contentData, "text", $event)}}})],1)])]),(_vm.contentData.type === 'image')?_c('div',{staticClass:"vue-dropzone-single"},[_c('ab-dropzone',{key:_vm.contentData.uid,attrs:{"data":_vm.contentData,"id":_vm.contentData.id},on:{"update:data":function($event){_vm.contentData=$event}}})],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }