<template>
  <div class="editor-item">
    <editor-header :showSettings="false" :title="$t('builder.downloads')"/>

    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <card-item>
        <ab-input-field
          :label="$t('app.title')"
          v-model="contentData.title"
          type="text"
          icon="icon-downloads"
        />
      </card-item>
      <draggable-item
        v-model="contentData.items"
        :group="'subitems' + contentData.id"
        animation="300"
        handle=".sub__drag"
        @start="dragging = true"
        @end="dragFinished"
      >
        <div v-for="(item, index) in contentData.items" :key="index">
          <div class="editor-item__sub-item">
            <div class="editor-item__sub-header">
              <div class="grid-x align-right">
                <div class="cell shrink">
                  <delete-card v-on:confirmDelete="deleteItem(index)"/>
                  <span class="sub__drag editor-item__button"
                  ><span class="sub__drag"
                  ><i class="icon-drag-and-drop"/></span
                  ></span>
                </div>
              </div>
            </div>

            <card-item>
              <ab-input-field
                :label="$t('builder.add_download_download_title')"
                v-model="item.title"
              />

              <div class="spacer--30"></div>
              <div class="dropzone-single-file__container">
                <ab-dropzone
                  :singleFileUpload="true"
                  type="file"
                  :data="item"
                  :id="index"
                  :relation="'page'"
                />
              </div>
            </card-item>
          </div>
        </div>
      </draggable-item>
      <hr/>
      <add-brand-data-button
        @add-item="addDownload"
        :title="$t('builder.add_download')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'cardDownloadFileList',
  props: {
    index: Number,
    contentItem: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      componentKey: 0,
      isVisible: true,
      id: null
    }
  },
  mounted () {
    this.id = this._uid
  },
  methods: {
    dragFinished () {
      this.componentKey += 1
      this.dragging = false
    },
    addDownload () {
      this.contentData.items.push({
        title: '',
        file: ''
      })
    },
    deleteItem: function (index) {
      this.contentData.items.splice(index, 1)
    }
  },
  computed: {
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    dragging: {
      get () {
        return this.dragStatus
      },
      set (val) {
        this.$emit('update:dragStatus', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  width: 100%;
}

textarea {
  padding: 0;
  height: auto;
  resize: none;
}

hr {
  margin: 0;
  border-color: $ui-line-color;
}

.card {
  overflow: visible;
}

.card__button {
  padding: 0;
  margin: 0;

  i {
    opacity: 1;
  }

  &:hover {
    background: none;
  }

  &::after {
    background: transparent;
  }
}

.editor-card-sort {
  margin: 0 0 10px;
  padding-right: 0;

  li {
    margin-top: 0;
  }
}

.card__title.card__title--icon-left {
  i {
    top: 37px;
  }
}

.editor-card-sort {
  margin: 0 0 10px;
  padding-right: 0;

  li {
    margin-top: 0;
  }
}

.editor-item__sub-item {
  border: 0;
  border-top: 2px solid $ui-line-color;
}
</style>
