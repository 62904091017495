<template>
  <div>
    <div>
      <div class="asset__list-container">
        <div class="search-bar">
          <div class="asset-search" v-if="autocompleteItems">
            <vue-tags-input
              v-model="tag"
              :tags="tags"
              :add-only-from-autocomplete="true"
              :placeholder="$t('app.search')"
              :autocomplete-items="filteredItems"
              @tags-changed="update"
            />
          </div>
        </div>
        <div class="grid-x grid-margin-x medium-up-3 grid card-list">
          <span
            v-for="(asset, index) in assets"
            :key="index"
            :to="{ name: 'AssetDetail', params: { pathName: asset.slug } }"
            class="cell grid__item card"
            @click="chooseAsset(asset)"
          >
            <div class="card__title">{{ asset.title }}</div>
            <figure class="card__image">
              <ul class="card__tags">
                <li>{{ asset.category.name }}</li>
              </ul>
              <v-lazy-image
                v-if="asset.preview_image"
                :src="asset.preview_image.url"
              />
            </figure>
          </span>
        </div>
        <intersection-observer @intersect="intersected"/>
      </div>
    </div>
    <div class="asset__section-close">
      <p class="text-right">
        <span class="modal__close" @click="$emit('close')">{{
            $t('app.close')
          }}</span>
        <span class="modal__save" @click="$emit('close')">{{
            $t('app.save')
          }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'
import VueTagsInput from '@johmun/vue-tags-input'
import VLazyImage from 'v-lazy-image'
import IntersectionObserver from '@/views/common/components/elements/IntersectionObserver'

export default {
  name: 'sidebarFileUpload',
  props: {
    item: Object,
    index: Number
  },
  data () {
    return {
      search: '',
      tag: '',
      tags: [],
      autocompleteItems: [],
      page: 1,
      assets: [],
      id: null
    }
  },
  created () {
    backend.get(`${process.env.VUE_APP_URL}/tags`).then(response => {
      for (const key in response.data) {
        const tag = response.data[key]
        if (tag.name !== '') {
          this.autocompleteItems.push({
            name: tag.name,
            type: 'tag',
            text: tag.name + '(tag)'
          })
        }
      }
    })

    backend
      .get(`${process.env.VUE_APP_URL}/assets/categories`)
      .then(response => {
        for (const key in response.data) {
          const category = response.data[key]
          if (category.name !== '') {
            this.autocompleteItems.push({
              name: category.name,
              type: 'category',
              text: category.name + '(category)'
            })
          }
        }
      })
  },
  methods: {
    chooseAsset (asset) {
      this.itemData.title = asset.title
      this.itemData.slug = asset.slug
      this.itemData.id = asset.id
      this.$emit('close')
    },
    async intersected () {
      backend.get(this.assetsUrl).then(response => {
        this.assets = [...this.assets, ...response.data]
      })
    },
    refreshAssets () {
      this.assets = []
      backend.get(this.assetsUrl).then(response => {
        this.assets = response.data
      })
    },
    update (newTags) {
      this.tags = newTags
      this.refreshAssets()
    }
  },
  computed: {
    itemData: {
      get () {
        return this.item
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    assetsUrl () {
      let url = `${process.env.VUE_APP_URL}/assets?`
      if (this.tags.length > 0) {
        for (const tagIndex in this.tags) {
          if (this.tags[tagIndex].type === 'tag') {
            url =
              url + `&filter[tags][${tagIndex}]=${this.tags[tagIndex].name}`
          }
          if (this.tags[tagIndex].type === 'category') {
            url = url + `&filter[category]=${this.tags[tagIndex].name}`
          }
        }
      }
      url = url + `&offset=${this.assets.length}&limit=39`
      return url
    },
    filteredItems () {
      if (this.tag.length < 1) return
      return this.autocompleteItems.filter(i => {
        return i.name.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
      })
    }
  },
  components: {
    VueTagsInput,
    VLazyImage,
    IntersectionObserver
  }
}
</script>

<style lang="scss" scoped>
input {
  width: 100%;
}

textarea {
  padding: 0;
  height: auto;
  resize: none;
}

hr {
  margin: 0;
  border-color: $ui-line-color;
}

.card {
  overflow: visible;
}

.card__button {
  padding: 0;
  margin: 0;

  i {
    opacity: 1;
  }

  &:hover {
    background: none;
  }

  &::after {
    background: transparent;
  }
}

.editor-card-sort {
  margin: 0 0 10px;
  padding-right: 0;

  li {
    margin-top: 0;
  }
}

.editor-item__sub-item {
  border: 0;
  border-top: 2px solid $ui-line-color;
}

.grid__item {
  border: 1px solid $ui-line-color;
}

.card__title {
  word-break: break-word;
}

.asset__section {
  padding-top: 0px;

  .asset-search {
    padding: 15px 60px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 50;
    background: $white;
    border-bottom: 1px solid $ui-line-color;

    &::before {
      left: 30px;
    }
  }

  &-close {
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    background: $white;
    border-top: 1px solid $ui-line-color;

    p {
      margin: 0;
    }
  }
}

.asset {
  &__list-container {
    overflow: scroll;
    height: 768px;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.search-bar {
  padding: calc(0px) calc(20px) calc(20px) calc(0px);
}

.asset-search {
  padding-top: 25px;
}
</style>
