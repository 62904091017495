<template>
  <div class="editor-item">
    <editor-header
      :title="title"
      :helperReference="contentData.page_block_id"
      :hasHelper="contentData.has_helper"
    />

    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <card-item v-if="contentData.items.length">
        <draggable-item
          class="grid-x grid-margin-x large-up-2 card-list"
          v-model="contentData.items"
          :group="'subitems' + contentData.id"
          animation="300"
          handle=".sub__drag"
          @start="dragging = true"
          @end="dragFinished"
        >
          <div
            v-for="(item, index) in contentData.items"
            :key="item.number"
            class="cell card background--center card-list__container"
            :dragStatus="dragging"
          >
            <div class="editor-item__sub-item">
              <div class="editor-item__sub-header">
                <div class="grid-x align-right">
                  <div class="cell shrink">
                    <dropdown-item
                      ref="dropdown"
                      :class="'editor-item__settings editor-item__button'"
                      :class-name="'custom'"
                      :isIcon="false"
                      :unscroll="'main'"
                      :align="'left'"
                      :y="27"
                      :x="27"
                    >
                      <template slot="btn">
                        <i class="icon-menu-settings"></i>
                      </template>
                      <template slot="body">
                        <div class="dropdown-edit">
                          <card-item>
                            <ab-select
                              :label="$t('builder.choose_size')"
                              :options="sizeOptions"
                              v-model="item.size"
                            />

                            <ab-select
                              :label="$t('builder.choose_background_type')"
                              :options="typeOptions"
                              v-model="item.type"
                            />

                            <ab-color-picker
                              v-if="item.type === 'color'"
                              :hex.sync="item.hex"
                              :value.sync="item.color"
                              type="large"
                            />
                          </card-item>
                        </div>
                      </template>
                    </dropdown-item>
                    <delete-card
                      v-on:confirmDelete="deleteItem(index)"
                    ></delete-card>
                    <span class="sub__drag editor-item__button"
                    ><i class="icon-drag-and-drop"
                    /></span>
                  </div>
                </div>
              </div>
              <div
                class="editor-item__sub-content"
                :class="[item.size]"
                :style="[
                  item.type === 'image'
                    ? {
                        backgroundImage: 'url(' + item.image + ')'
                      }
                    : { backgroundColor: item.color }
                ]"
              >
                <div
                  :class="[sizeOptions]"
                  class="page-banner background--center"
                  :style="[
                    typeOptions === 'image'
                      ? {
                          backgroundImage: 'url(' + item.image + ')'
                        }
                      : { backgroundColor: '#' + item.color }
                  ]"
                >
                  <div class="page-banner__content">
                    <div class="grid-x align-middle">
                      <div class="cell content">
                        <content-editor
                          :disabled="dragging"
                          :contentData.sync="item.text"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="vue-dropzone-single" v-if="item.type === 'image'">
                    <ab-dropzone :data="item" :id="index" :key="item.number"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </draggable-item>
      </card-item>
      <hr v-if="contentData.items.length > 0"/>
      <add-brand-data-button
        @add-item="addCard"
        :title="$t('builder.add_image')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'cardList',
  props: {
    index: Number,
    contentItem: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      componentKey: 0,
      isVisible: true,
      sizeOptions: [
        { title: this.$t('app.small'), value: 'card--small' },
        { title: this.$t('app.medium'), value: 'card--medium' },
        { title: this.$t('app.large'), value: 'card--large' }
      ],
      typeOptions: [
        { title: this.$t('app.background_color'), value: 'color' },
        { title: this.$t('app.background_image'), value: 'image' }
      ]
    }
  },
  created () {
    for (let i = 0; i < this.contentData.items.length; i++) {
      this.contentData.items[i].number = i
    }
  },
  methods: {
    dragFinished () {
      this.dragging = false
      this.componentKey += 1
    },
    addCard () {
      this.contentData.items.push({
        text: '',
        color: 'FFFFFF',
        hex: 'FFFFFF',
        image: '',
        number: this.contentData.items.length
      })
    },
    deleteItem: function (index) {
      this.componentKey += 1
      this.contentData.items.splice(index, 1)
    }
  },
  computed: {
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    title: function () {
      if (this.contentData._title) {
        return this.contentData._title
      }
      return this.$t('builder.images')
    },
    dragging: {
      get () {
        return this.dragStatus
      },
      set (val) {
        this.$emit('update:dragStatus', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  overflow: visible;
}

.editor-card-sort {
  z-index: 50;
  position: absolute;
  top: 0;
  right: 0;
}

.card__content {
  z-index: 11;
  position: relative;
}

.vue-dropzone {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .dz-preview {
    height: 100%;
  }
}

.editor-item__sub-content {
  flex-direction: column;
  align-content: center;
}

.page-banner {
  background: none;
  flex-grow: 1;
}

.card-list__container {
  &:nth-child(n + 3) {
    margin-top: 30px;
  }
}

.vue-dropzone-single--absolute {
  left: 0;
}
</style>
