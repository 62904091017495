<template>
  <div class="editor-item__sub-item">
    <div class="editor-item__sub-header">
      <div class="grid-x align-right">
        <div class="cell shrink">
          <span
            class="editor-item__minimize editor-item__button"
            @click="minimize"
          ><i
            :class="[isVisible ? 'icon-chevron-up' : 'icon-chevron-down']"
          ></i
          ></span>
          <delete-card v-on:confirmDelete="deleteItem"></delete-card>
          <span class="sub__drag editor-item__button"
          ><i class="icon-drag-and-drop"></i
          ></span>
        </div>
      </div>
    </div>
    <div class="editor-item__sub-content" :class="[isVisible ? '' : 'hide']">
      <div class="grid-x">
        <div class="cell auto">
          <card-item>
            <content-editor
              :disabled="dragStatus"
              :contentData.sync="itemData.text"
            ></content-editor>
          </card-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextCard',
  props: {
    index: Number,
    item: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      isVisible: true
    }
  },
  computed: {
    itemData: {
      get () {
        return this.item
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    minimize: function () {
      this.isVisible = !this.isVisible
    },
    deleteItem: function () {
      this.$emit('deleteItem', this.index)
    }
  }
}
</script>

<style lang="scss" scoped></style>
