export const dirtyRoute = {
  data () {
    return {
      dirty: false
    }
  },
  methods: {
    pageLeaveAction (e) {
      // Cancel the event
      e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = ''
    },
    addUnloadPrevention () {
      window.addEventListener('beforeunload', this.pageLeaveAction)
    },
    removeUnloadPrevention () {
      window.removeEventListener('beforeunload', this.pageLeaveAction)
    },
    setDirty (dirty) {
      this.dirty = dirty
      if (dirty) {
        this.addUnloadPrevention()
      } else {
        this.removeUnloadPrevention()
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.dirty) {
      this.$modal.show('dialog', {
        title: '',
        text: this.$t('app.confirm_unsaved_changed'),
        buttons: [
          {
            title: this.$t('app.no'),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('app.yes'),
            default: true,
            handler: () => {
              this.$modal.hide('dialog')
              this.removeUnloadPrevention()
              next()
            }
          }
        ]
      })
    } else {
      this.removeUnloadPrevention()
      next()
    }
  }
}
