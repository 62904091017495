<template>
  <div class="editor-item">
    <editor-header
      :title="title"
      :helperReference="contentData.page_block_id"
      :hasHelper="contentData.has_helper"
    />
    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <card-item>
        <content-editor
          :disabled="dragging"
          :contentData.sync="contentData.text"
        />
        <div v-if="contentData.items.length" class="spacer--30"/>
        <draggable-item
          class="color-ratio__wrapper grid-x grid-padding-x"
          v-model="contentData.items"
          :group="'subitems' + contentData.id"
          animation="300"
          handle=".sub__drag"
          @start="dragging = true"
          @end="dragging = false"
        >
          <color-ratio-item
            v-for="(item, subindex) in contentData.items"
            :key="subindex"
            :dragStatus="dragging"
            :item="item"
            :index="subindex"
            v-on:deleteItem="deleteItem"
          />
        </draggable-item>
      </card-item>
      <hr/>
      <add-brand-data-button
        @add-item="addRatio"
        :title="$t('builder.add_color_ratio')"
      />
    </div>
  </div>
</template>

<script>
import ColorRatioItem from '@/views/brand/components/edit/content/CardComponents/ColorRatioItem'

export default {
  name: 'cardColorRatio',
  props: {
    index: Number,
    contentItem: Object,
    dragStatus: Boolean
  },
  components: {
    ColorRatioItem
  },
  data () {
    return {
      isVisible: true
    }
  },
  methods: {
    addRatio () {
      let totalPercentage = 0
      this.contentData.items.forEach(item => {
        totalPercentage += parseInt(item.percentage)
      })
      this.contentData.items.push({
        percentage: 100 - totalPercentage,
        title: '000000'
      })
    },
    deleteItem: function (index) {
      this.contentData.items.splice(index, 1)
    }
  },
  computed: {
    title: function () {
      if (this.contentItem._title) {
        return this.contentItem._title
      }
      return this.$t('builder.color_ratio')
    },
    dragging: {
      get () {
        return this.dragStatus
      },
      set (val) {
        this.$emit('update:dragStatus', val)
      }
    },
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  width: 100%;
}

.color-ratio {
  &__wrapper {
    margin: 0;
  }

  &__item {
    min-width: 100px;
    padding: 0px 5px 0px 0px;

    .edit-group-label {
      width: 45px;
      text-align: center;
    }
  }
}

textarea {
  padding: 0;
  height: auto;
  resize: none;
}

hr {
  margin: 0;
  border-color: $ui-line-color;
}

.card {
  overflow: visible;
}

.editor-card-sort {
  padding-right: 0;

  li {
    margin-top: 0;
  }
}
</style>
