<template>
  <div @click="$emit('add-item')" class="builder-modal__item">
    <div class="grid-x grid-margin-x">
      <div class="shrink cell">
        <img :src="image" alt="" />
      </div>
      <div class="auto cell">
        <h3 class="light">{{ title }}</h3>
        <p>{{ explanation }}</p>
        <p>
          <span>{{ $t("builder.add") }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrandDataItem',
  props: {
    image: String,
    title: String,
    explanation: String
  }
}
</script>

<style lang="scss">
.builder-modal {
  &__header {
    position: sticky;
    top: 0;
    background: white;
    padding: 0 30px;
    border-bottom: 1px solid $ui-line-color;
  }
  &__item {
    @include transition(all);
    cursor: pointer;
    padding: 30px;
    + .builder-modal__item {
      border-top: 1px solid $ui-line-color;
    }
    &:hover {
      background: rgba($ui-line-color, 0.2);
    }
  }
  h2 {
    color: $black;
  }
  p {
    margin-top: 15px;
    line-height: 1.4;
    &:last-child {
      text-align: right;
    }
    span {
      font-weight: 500;
      color: $light-gray;
      text-transform: uppercase;
    }
  }
}
.builder-tab {
  margin: 0;
  padding: 0;
  &__item {
    &::before {
      display: none;
    }
    padding: 25px 0 20px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    opacity: 0.5;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    &:hover,
    &.is-active {
      border-color: $black;
      opacity: 1;
    }
    + li {
      margin-left: 25px;
    }
  }
}
</style>
