<template>
  <div class="editor-item__sub-item">
    <div class="editor-item__sub-header">
      <div class="grid-x align-right">
        <div class="cell shrink">
          <span class="editor-item__minimize editor-item__button" @click="minimize">
            <i :class="[isVisible ? 'icon-chevron-up' : 'icon-chevron-down']"></i>
          </span>
          <delete-card v-on:confirmDelete="deleteItem(index)"></delete-card>
          <span class="sub__drag editor-item__button"><i class="icon-drag-and-drop"></i></span>
        </div>
      </div>
    </div>
    <div class="editor-item__sub-content" :class="[isVisible ? '' : 'hide']">
      <div class="grid-x">
        <div class="cell medium-4 br-2">
          <card-item>
            <div class="color-list__color" :style="{ backgroundColor: '#' + itemData.hex }">
              <div class="color-list__color-inner">
                <span>
                  A a<br/>
                  <small v-if="itemData.dark_is_correct === '1'">{{ $t('app.good') }}</small>
                  <small v-else>{{ $t('app.wrong') }}</small>
                </span>
                <span class="color--white">
                  A a<br/>
                  <small v-if="itemData.light_is_correct === '1'">{{ $t('app.good') }}</small>
                  <small v-else>{{ $t('app.wrong') }}</small>
                </span>
              </div>
            </div>
            <div class="color-list__readability">
              <div class="grid-x align-justify">
                <div class="cell auto">
                  <p>{{ $t('builder.readability_dark_text') }}</p>
                </div>
                <div class="cell shrink">
                  <label :class="[itemData.dark_is_correct === '1' ? 'is-active' : '']" :for="'ja' + id + index + 'dark'">
                    {{ $t('app.good') }}
                    <input type="radio" :id="'ja' + id + index + 'dark'" value="1" v-model="itemData.dark_is_correct"/>
                  </label>
                  <label :class="[itemData.dark_is_correct === '0' ? 'is-active' : '']" :for="'no' + id + index + 'dark'">
                    {{ $t('app.wrong') }}
                    <input type="radio" :id="'no' + id + index + 'dark'" value="0" v-model="itemData.dark_is_correct"/>
                  </label>
                </div>
              </div>
              <div class="grid-x align-justify">
                <div class="cell auto">
                  <p>{{ $t('builder.readability_light_text') }}</p>
                </div>
                <div class="cell shrink">
                  <label :class="[itemData.light_is_correct === '1' ? 'is-active' : '']" :for="'ja' + id + index + 'light'">
                    {{ $t('app.good') }}
                    <input type="radio" :id="'ja' + id + index + 'light'" value="1" v-model="itemData.light_is_correct"/>
                  </label>
                  <label :class="[itemData.light_is_correct === '0' ? 'is-active' : '']" :for="'no' + id + index + 'light'">
                    {{ $t('app.wrong') }}
                    <input type="radio" :id="'no' + id + index + 'light'" value="0" v-model="itemData.light_is_correct"/>
                  </label>
                </div>
              </div>
            </div>
          </card-item>
        </div>
        <div class="cell medium-8">
          <card-item>
            <div class="grid-x grid-margin-x edit-group-container">
              <div class="cell auto">
                <ab-input-field v-model="itemData.title" :placeholder="$t('app.title')" :label="$t('app.title')"/>
              </div>
            </div>
            <ab-color-combo :rgb.sync="itemData.rgb" :hex.sync="itemData.hex" :cmyk.sync="itemData.cmyk"/>
            <div class="grid-x grid-margin-x edit-group-container pt-30">
              <div class="cell auto">
                <ab-input-field v-model="itemData.pantone" :placeholder="$t('app.pantone')" :label="$t('app.pantone')"/>
              </div>
            </div>
            <div class="grid-x grid-margin-x edit-group-container pt-10">
              <div class="cell auto">
                <ab-input-field v-model="itemData.ral" :placeholder="$t('app.ral')" :label="$t('app.ral')"/>
              </div>
            </div>
          </card-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'colorListItem',
  props: {
    index: Number,
    item: Object
  },
  data () {
    return {
      isVisible: true,
      id: null
    }
  },
  mounted () {
    this.id = this._uid
  },
  computed: {
    itemData: {
      get () {
        return this.item
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    minimize: function () {
      this.isVisible = !this.isVisible
    },
    deleteItem: function () {
      this.$emit('deleteItem', this.index)
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-group {
  &--multiple {
    input {
      + input {
        border-left: 1px solid $ui-line-color;
      }
    }
  }
}

.card {
  overflow: visible;
}

.card-colors__header {
  padding-top: 0;
  padding-right: 0;
  position: relative;
}

.editor-card-sort {
  margin-bottom: 50px;
}

.color-list {
  &__color {
    transition: background-color;
    height: 220px;
    padding: 20px;
    border-radius: $global-radius;
    border: 2px solid $ui-line-color;
    position: relative;
    margin-bottom: 15px;

    span {
      width: 49px;
      display: inline-block;
      font-size: 14px;
    }

    &-inner {
      position: absolute;
      bottom: 0;
      padding: 20px;
      left: 0;
      width: 100%;
    }
  }

  &__readability {
    p {
      font-size: 14px;
    }

    label {
      transition: 150ms ease-in-out color;
      text-transform: uppercase;
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      color: #c4c4c4;
      cursor: pointer;

      input {
        margin: 0;
        visibility: hidden;
      }

      &.is-active {
        color: #000000;
      }
    }
  }
}

.ab-button--plain {
  width: 140px;
  text-align: center;
}

.edit-group-container {
  margin-bottom: 18px;

  &:last-child {
    margin-bottom: 0;
  }
}

.overflow-visible {
  overflow: visible;
}
</style>
