<template>
  <div class="editor-item__sub-item">
    <div class="editor-item__sub-header">
      <div class="grid-x align-right">
        <div class="cell shrink">
          <span
            class="editor-item__minimize editor-item__button"
            @click="minimize"
          ><i
            :class="[isVisible ? 'icon-chevron-up' : 'icon-chevron-down']"
          ></i
          ></span>
          <dropdown-item
            ref="dropdown"
            v-if="itemData.type !== 'text-text-text' && itemData.type !== 'text'"
            :class="'editor-item__settings editor-item__button'"
            :class-name="'custom'"
            :isIcon="false"
            :unscroll="'main'"
            :align="'left'"
            :y="27"
            :x="27"
          >
            <template slot="btn">
              <i class="icon-menu-settings"></i>
            </template>
            <template slot="body">
              <div class="dropdown-edit">
                <card-item>
                  <ab-select
                    :label="$t('builder.choose_column_size')"
                    :options="columnSize"
                    v-model="itemData.columns"
                  ></ab-select>
                </card-item>
              </div>
            </template>
          </dropdown-item>
          <delete-card v-on:confirmDelete="deleteItem"></delete-card>
          <span class="sub__drag editor-item__button"
          ><i class="icon-drag-and-drop"></i
          ></span>
        </div>
      </div>
    </div>
    <div class="editor-item__sub-content" :class="[isVisible ? '' : 'hide']">
      <div class="grid-x">
        <div class="cell br-2" :class="'medium-' + columnWidth(itemData.columns)">
          <card-item>
            <content-editor
              :disabled="dragStatus"
              :contentData.sync="itemData.text"
            ></content-editor>
          </card-item>
        </div>
        <div class="cell auto">
          <card-item>
            <content-editor
              :disabled="dragStatus"
              :contentData.sync="itemData.text_col_two"
            ></content-editor>
          </card-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextTextCard',
  props: {
    index: Number,
    item: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      isVisible: true,
      columnSize: [
        { title: '1-1', value: '1-1' },
        { title: '1-2', value: '1-2' },
        { title: '2-1', value: '2-1' }
      ],
      hideSettings: undefined
    }
  },
  computed: {
    itemData: {
      get () {
        return this.item
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    minimize: function () {
      this.isVisible = !this.isVisible
    },
    deleteItem: function () {
      this.$emit('deleteItem', this.index)
    },
    columnWidth (item) {
      if (item === '1-2') {
        return '4'
      } else if (item === '2-1') {
        return '8'
      } else {
        return '6'
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
