<template>
  <div class="editor-item">
    <milestone-list-item/>
    <editor-header
      :title="title"
      :hasHelper="contentData.has_helper"
      :helperReference="contentData.page_block_id"
    />
    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <draggable-item
        v-model="contentData.items"
        :group="'subitems' + contentData.id"
        animation="300"
        handle=".sub__drag"
        @start="dragging = true"
        @end="dragging = false"
      >
        <div
          v-for="(item, subindex) in contentData.items"
          :key="subindex"
          :class="'editor-item__sub-container'"
          :dragStatus="dragging"
        >
          <milestone-list-item
            :item="item"
            :index="subindex"
            :key="item.number"
            v-on:deleteItem="deleteItem"
          />
        </div>
      </draggable-item>
      <hr v-if="contentData.items !== undefined && contentData.items.length > 0"/>
      <add-brand-data-button
        @add-item="addCard"
        :title="$t('builder.milestone_add')"
      />
    </div>
  </div>
</template>

<script>
import milestoneListItem from './CardComponents/MilestoneListItem.vue'

export default {
  name: 'MilestoneList',
  props: {
    contentItem: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      unique_id: 1,
      isVisible: true
    }
  },
  created () {
    for (let i = 0; i < this.contentData.items.length; i++) {
      this.unique_id++
      this.contentData.items[i].number = this.unique_id
    }
  },
  methods: {
    addCard () {
      this.unique_id++
      this.contentData.items.push({
        title: '',
        year: '',
        description: '',
        layout: '',
        size: '',
        number: this.unique_id
      })
    },
    deleteItem: function (index) {
      this.contentData.items.splice(index, 1)
    }
  },
  computed: {
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    title: function () {
      if (this.contentData._title) {
        return this.contentData._title
      }
      return this.$t('builder.milestone_component')
    },
    dragging: {
      get () {
        return this.dragStatus
      },
      set (val) {
        this.$emit('update:dragStatus', val)
      }
    }
  },
  components: {
    'milestone-list-item': milestoneListItem
  }
}
</script>

<style lang="scss" scoped>
input {
  font-size: 26px;
  font-weight: 300;
  color: #454545;
  padding: 0;
  background: transparent;
  height: auto;
  width: 100%;
  border: none;

  &:focus {
    background: transparent;
  }
}
</style>
