<template>
  <div class="editor-item">
    <editor-header
      :title="title"
      :helperReference="contentData.page_block_id"
      :hasHelper="contentData.has_helper"
    />
    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <card-item>
        <content-editor
          :disabled="dragStatus"
          :contentData.sync="contentData.text"
        />
      </card-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardContent',
  props: {
    contentItem: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      isVisible: true
    }
  },
  computed: {
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    title: function () {
      if (this.contentData._title) {
        return this.contentData._title
      }
      return this.$t('builder.text_block')
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
  padding: 0;
  height: auto;
  resize: none;
}
</style>
