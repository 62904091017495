<template>
  <div class="editor-item">
    <editor-header
      :item="contentData"
      :title="title"
      :helperReference="contentData.page_block_id"
      :hasHelper="contentData.has_helper"
    >
      <template slot="settings">
        <dropdown-item
          ref="dropdown"
          :class="'editor-item__settings editor-item__button'"
          :class-name="'custom'"
          :isIcon="false"
          :unscroll="'main'"
          :align="'left'"
          :y="27"
          :x="27"
        >
          <template slot="btn">
            <i class="icon-menu-settings"></i>
          </template>
          <template slot="body">
            <div class="dropdown-edit">
              <card-item>
                <ab-select
                  :label="$t('builder.choose_size')"
                  :options="sizeOptions"
                  v-model="contentData.size"
                ></ab-select>
                <ab-select
                  :label="$t('builder.choose_background_type')"
                  :options="typeOptions"
                  v-model="contentData.type"
                ></ab-select>
                <ab-color-picker
                  v-if="contentData.type === 'color'"
                  :hex.sync="contentData.hex"
                  :value.sync="contentData.color"
                  :label="$t('builder.choose_background_color')"
                  type="large"
                ></ab-color-picker>
                <ab-select
                  :label="$t('builder.choose_alignment')"
                  :options="alignmentOptions"
                  v-model="contentData.alignment"
                ></ab-select>
              </card-item>
            </div>
          </template>
        </dropdown-item>
      </template>
    </editor-header>
    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <div
        :class="[contentData.size]"
        class="page-banner background--center"
        :style="[
          contentData.type === 'image'
            ? {
                backgroundImage: 'url(' + contentData.image + ')'
              }
            : { backgroundColor: '#' + contentData.color }
        ]"
      >
        <div class="page-banner__content">
          <div
            class="grid-x align-middle"
            :class="[contentData.alignment === 'right' ? 'align-right' : '']"
          >
            <div
              class="cell content"
              :class="[
                contentData.alignment === 'fullWidth' ? 'auto' : 'medium-6'
              ]"
            >
              <content-editor
                :disabled="dragStatus"
                :contentData.sync="contentData.text"
              />
            </div>
          </div>
        </div>
        <div class="vue-dropzone-single" v-if="contentData.type === 'image'">
          <ab-dropzone
            :data.sync="contentData"
            :id="contentData.id"
            :key="contentData.uid"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageBanner',
  props: {
    contentItem: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      isVisible: true,
      sizeOptions: [
        { title: this.$t('app.extra-small'), value: 'card--extra-small' },
        { title: this.$t('app.small'), value: 'card--small' },
        { title: this.$t('app.medium'), value: 'card--medium' },
        { title: this.$t('app.large'), value: 'card--large' },
        { title: this.$t('app.extraLarge'), value: 'card--extra-large' }
      ],
      alignmentOptions: [
        { title: this.$t('app.left'), value: 'left' },
        { title: this.$t('app.right'), value: 'right' },
        { title: this.$t('app.full_width'), value: 'fullWidth' }
      ],
      typeOptions: [
        { title: this.$t('app.background_color'), value: 'color' },
        { title: this.$t('app.background_image'), value: 'image' }
      ]
    }
  },
  computed: {
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    title: function () {
      if (this.contentData._title) {
        return this.contentData._title
      }
      return this.$t('builder.banner')
    }
  }
}
</script>

<style lang="scss" scoped>
hr {
  margin: 0;
  border-color: $ui-line-color;
}

.page-banner {
  &__content {
    z-index: 11;
    position: relative;
  }
}

.editor-card-sort {
  z-index: 50;
  position: absolute;
  top: 0;
  right: 0;
}
</style>
