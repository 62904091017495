<template>
  <div class="editor-item">
    <editor-header
      :title="title"
      :helperReference="contentData.page_block_id"
      :hasHelper="contentData.has_helper"
    />
    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <card-item>
        <content-editor
          :disabled="dragStatus"
          :contentData.sync="contentData.text"
        />
      </card-item>
    </div>

    <div v-if="contentData.duration || contentData.priority != ''">
      <hr/>
      <card-item>
        <div class="grid-x">
          <div
            class="cell shrink pr-20 card__intro-duration"
            v-if="contentData.duration"
          >
            <p class="mb-0">
              <span v-if="contentData.duration === '1'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="13"
                  viewBox="0 0 23 13"
                >
                  <g transform="translate(-193 -379)">
                    <line
                      class="a"
                      y2="10"
                      transform="translate(194.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(199.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(204.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(209.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(214.5 380.5)"
                    />
                  </g>
                </svg>
              </span>
              <span v-else-if="contentData.duration === '2'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="13"
                  viewBox="0 0 23 13"
                >
                  <g transform="translate(-193 -379)">
                    <line
                      class="a"
                      y2="10"
                      transform="translate(194.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(199.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(204.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(209.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(214.5 380.5)"
                    />
                  </g>
                </svg>
              </span>
              <span v-else-if="contentData.duration === '3'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="13"
                  viewBox="0 0 23 13"
                >
                  <g transform="translate(-193 -379)">
                    <line
                      class="a"
                      y2="10"
                      transform="translate(194.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(199.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(204.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(209.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(214.5 380.5)"
                    />
                  </g>
                </svg>
              </span>
              <span v-else-if="contentData.duration === '4'">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="13"
                  viewBox="0 0 23 13"
                >
                  <g transform="translate(-193 -379)">
                    <line
                      class="a"
                      y2="10"
                      transform="translate(194.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(199.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(204.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(209.5 380.5)"
                    />
                    <line
                      class="b"
                      y2="10"
                      transform="translate(214.5 380.5)"
                    />
                  </g>
                </svg>
              </span>
              <span v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="13"
                  viewBox="0 0 23 13"
                >
                  <g transform="translate(-193 -379)">
                    <line
                      class="a"
                      y2="10"
                      transform="translate(194.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(199.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(204.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(209.5 380.5)"
                    />
                    <line
                      class="a"
                      y2="10"
                      transform="translate(214.5 380.5)"
                    />
                  </g>
                </svg>
              </span>
              <ab-select
                :label="$t('brand.intro_complexity')"
                :options="durationOptions"
                v-model="contentData.duration"
              ></ab-select>
            </p>
          </div>
          <div
            class="cell shrink card__intro-priority"
            v-if="contentData.priority"
          >
            <p class="mb-0">
              <i
                class="icon-priority"
                :class="priorityColor(contentData.priority)"
              />
              <ab-select
                :label="$t('brand.priority')"
                :options="priorityOptions"
                v-model="contentData.priority"
              ></ab-select>
            </p>
          </div>
        </div>
      </card-item>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardIntro',
  props: {
    contentItem: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      isVisible: true,
      durationOptions: [
        { title: this.$t('states.simple'), value: '1' },
        { title: this.$t('states.easy'), value: '2' },
        { title: this.$t('states.normal'), value: '3' },
        { title: this.$t('states.complex'), value: '4' },
        { title: this.$t('states.expert'), value: '5' }
      ],
      priorityOptions: [
        { title: this.$t('states.low'), value: 'low' },
        { title: this.$t('states.medium'), value: 'medium' },
        { title: this.$t('states.high'), value: 'high' }
      ]
    }
  },
  methods: {
    priorityColor (item) {
      if (item === 'high') {
        return 'color--error'
      } else if (item === 'medium') {
        return 'color--warning'
      } else {
        return 'color--valid'
      }
    }
  },
  computed: {
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    title: function () {
      if (this.contentData._title) {
        return this.contentData._title
      }
      return this.$t('builder.intro')
    }
  }
}
</script>

<style lang="scss" scoped>
hr {
  margin: 0;
  border: 0;
  border-top: 1px solid $ui-line-color;
}

.a,
.b {
  fill: none;
  stroke-linecap: round;
  stroke-width: 3px;
}

.a {
  stroke: #454545;
}

.b {
  stroke: $ui-line-color;
}

.card {
  &__intro-duration,
  &__intro-priority {
    p {
      display: flex;

      i,
      svg {
        position: relative;
        top: 17px;
        margin-right: 10px;
      }
    }
  }
}
</style>
