<template>
  <div class="builder-modal" v-if="type">
    <tab-list
      tabItems="builder-tab"
      tabItem="builder-tab__item"
      tabHeaderContainer="builder-modal__header"
      tabContentContainer="builder-modal__content"
    >
      <tab-item :name="$t('builder.page_blocks')" :selected="true">
        <div v-if="specificPageBlocks.length > 0">
          <brand-data-item
            v-for="(item, i) in specificPageBlocks"
            :key="i"
            :image="'https://via.placeholder.com/241x100?text=' + item.title"
            :title="item.title"
            :explanation="item.description"
            @add-item="addSpecificBlock(item)"
          />
        </div>
        <div v-else>
          <div class="spacer--50"></div>
          <h6 class="text-center">{{ $t('builder.no_specific_blocks') }}</h6>
          <div class="spacer--50"></div>
        </div>
      </tab-item>
      <tab-item :name="$t('builder.basic_blocks')">
        <brand-data-item
          v-if="$route.name === 'BrandDescribeEdit'"
          image="/assets/images/icons/content/add_card_intro.jpg"
          :title="$t('builder.intro')"
          :explanation="$t('builder.intro_explanation')"
          @add-item="addCardIntro"
        />
        <brand-data-item
          image="/assets/images/editor/content/add_title.jpg"
          :title="$t('builder.title')"
          :explanation="$t('builder.title_explanation')"
          @add-item="addChapterTitle"
        />
        <brand-data-item
          image="/assets/images/editor/content/add_textblocks.jpg"
          :title="$t('builder.text_blocks')"
          :explenation="$t('builder.text_blocks_explanation')"
          @add-item="addContentRepeater"
        />
        <brand-data-item
          image="/assets/images/editor/content/add_banner.jpg"
          :title="$t('builder.banner')"
          :explanation="$t('builder.banner_explanation')"
          @add-item="addPageBanner"
        />
        <brand-data-item
          image="/assets/images/editor/content/add_milestones.jpg"
          :title="$t('builder.milestone_component')"
          :explanation="$t('builder.milestone_component_explanation')"
          @add-item="addMilestoneList"
        />
        <brand-data-item
          image="/assets/images/editor/content/add_images.jpg"
          :title="$t('builder.split_banner')"
          :explanation="$t('builder.split_banner_explanation')"
          @add-item="addSplitBanner"
        />
        <brand-data-item
          image="/assets/images/editor/content/add_downloads.jpg"
          :title="$t('builder.downloads')"
          :explanation="$t('builder.downloads_explanation')"
          @add-item="addCardDownloadList"
        />
        <brand-data-item
          image="/assets/images/editor/content/add_wrong_examples.jpg"
          :title="$t('builder.wrong_examples')"
          :explanation="$t('builder.wrong_examples_explanation')"
          @add-item="addWrongUsageRepeater"
        />
        <brand-data-item
          image="/assets/images/editor/content/add_colors.jpg"
          :title="$t('builder.colors')"
          :explanation="$t('builder.colors_explanation')"
          @add-item="addColorList"
        />
        <brand-data-item
          image="/assets/images/editor/content/add_color_ratio.jpg"
          :title="$t('builder.color_ratio')"
          :explanation="$t('builder.color_ratio_explanation')"
          @add-item="addColorRatio"
        />
        <brand-data-item
          image="/assets/images/editor/content/add_color_gradient.jpg"
          :title="$t('builder.color_gradient')"
          :explanation="$t('builder.color_gradient_explanation')"
          @add-item="addColorGradient"
        />
        <brand-data-item
          image="/assets/images/editor/content/add_color_usage.jpg"
          :title="$t('builder.color_usage')"
          :explanation="$t('builder.color_usage_explanation')"
          @add-item="addCardColorUsage"
        />
        <brand-data-item
          v-if="$route.params.pathName === 'dashboard'"
          image="/assets/images/editor/content/add_latest_updates.jpg"
          :title="$t('builder.latest_updates')"
          :explanation="$t('builder.latest_updates_explanation')"
          @add-item="addUpdateList"
        />
        <brand-data-item
          v-if="$route.params.pathName === 'dashboard'"
          image="/assets/images/editor/content/add_recent_assets.jpg"
          :title="$t('builder.recent_assets')"
          :explanation="$t('builder.recent_assets_explanation')"
          @add-item="addRecentAssets"
        />
        <brand-data-item
          v-if="$route.params.pathName === 'dashboard'"
          image="/assets/images/editor/content/add_dashboard_buttons.png"
          :title="$t('builder.dashboard_buttons')"
          :explanation="$t('builder.dashboard_buttons_explanation')"
          @add-item="addDashboardButtons"
        />
      </tab-item>
    </tab-list>
  </div>
  <div class="builder-modal" v-else>
    <tab-list
      tabItems="builder-tab"
      tabItem="builder-tab__item"
      tabHeaderContainer="builder-modal__header"
      tabContentContainer="builder-modal__content"
    >
      <tab-item :name="$t('builder.sidebar')" :selected="true">
        <brand-data-item
          image="/assets/images/editor/sidebar/add_literature.jpg"
          :title="$t('builder.literature')"
          :explanation="$t('builder.literature_explanation')"
          @add-item="addSidebarLiteratureList"
        />
        <brand-data-item
          image="/assets/images/editor/sidebar/add_examples.jpg"
          :title="$t('builder.examples')"
          :explanation="$t('builder.examples_explanation')"
          @add-item="addSidebarExampleList"
        />
        <brand-data-item
          image="/assets/images/editor/sidebar/add_downloads.jpg"
          :title="$t('builder.downloads')"
          :explanation="$t('builder.downloads_sidebar_explanation')"
          @add-item="addSidebarDownloadFileList"
        />
        <brand-data-item
          image="/assets/images/editor/sidebar/add_content.jpg"
          :title="$t('builder.content')"
          :explanation="$t('builder.content_explanation')"
          @add-item="addSidebarContent"
        />
        <brand-data-item
          image="/assets/images/editor/sidebar/add_content.jpg"
          :title="$t('builder.related_assets')"
          :explanation="$t('builder.related_assets_explanation')"
          @add-item="addSidebarRelatedAssets"
        />
      </tab-item>
    </tab-list>
  </div>
</template>

<script>
import chapterTitle from './../../content/ChapterTitle'
import pageBanner from './../../content/PageBanner'
import cardIntro from './../../content/CardIntro'
import cardContentRepeater from './../../content/CardContentRepeater'
import cardColorRatio from './../../content/CardColorRatio'
import cardColorList from './../../content/CardColorList'
import cardColorGradient from './../../content/CardColorGradient'
import cardWrongUsageRepeater from './../../content/CardWrongUsageRepeater'
import cardDownloadList from './../../content/CardDownloadList'
import cardColorUsage from './../../content/CardColorUsage'
import cardContent from './../../content/CardContent'
import cardDownloadFileList from './../../sidebar/CardDownloadFileList'
import cardExampleList from './../../sidebar/CardExampleList'
import cardLiteratureList from './../../sidebar/CardLiteratureList'
import dashboardButtons from './../../content/DashboardButtons'
import updateList from './../../content/UpdateList'
import RecentAssets from './../../content/RecentAssets'
import CardRelatedAssets from './../../sidebar/CardRelatedAssets'
import BrandDataItem from './BrandDataItem'
import splitBanner from './../../content/SplitBanner'
import milestoneList from './../../content/MilestoneList'

import TabList from '@/views/common/components/tabs/TabList'
import TabItem from '@/views/common/components/tabs/TabItem'

import backend from '@/backend'

export default {
  name: 'addBrandDataTest',
  props: {
    id: Number,
    type: Boolean,
    brandData: Object
  },
  data () {
    return {
      specificPageBlocks: []
    }
  },
  mounted () {
    backend
      .get(
        `${process.env.VUE_APP_URL}/pages/${this.$route.params.pathName}/blocks`
      )
      .then(response => {
        let blocks = response.data
        blocks = blocks.filter(block => {
          let show = true
          this.localBrandData.value.forEach(i => {
            if (block.id === i.page_block_id) {
              show = false
            }
          })
          return show
        })
        this.specificPageBlocks = blocks
      })
  },
  computed: {
    valueNewUid: function () {
      return this._uid
    },
    localBrandData: {
      get () {
        return this.brandData
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    scrollToTop (value) {
      this.$nextTick(() => {
        const offset = document.getElementById(`page-block--${value}`).offsetTop
        document.getElementById('pageContent').scrollTo({
          top: offset - 180,
          behavior: 'smooth'
        })
      })
      this.$emit('close')
    },
    addSpecificBlock (value) {
      this.localBrandData.value.splice(this.id + 1, 0, {
        component: value.component,
        _group: value.component,
        colors: [],
        items: [],
        page_block_id: value.id,
        reference: value.reference,
        _title: value.title,
        uid: this.valueNewUid,
        has_helper: value.helper.length > 0
      })
      this.scrollToTop(this.valueNewUid)
    },
    addChapterTitle () {
      this.localBrandData.value.splice(this.id + 1, 0, {
        component: chapterTitle,
        _group: 'chapterTitle',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addPageBanner () {
      this.localBrandData.value.splice(this.id + 1, 0, {
        component: pageBanner,
        _group: 'pageBanner',
        uid: this.valueNewUid,
        color: 'FFFFFF'
      })
      this.scrollToTop(this.valueNewUid)
    },
    addSplitBanner () {
      this.localBrandData.value.splice(this.id + 1, 0, {
        items: [],
        component: splitBanner,
        _group: 'splitBanner',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addMilestoneList () {
      this.localBrandData.value.splice(this.id + 1, 0, {
        items: [],
        component: milestoneList,
        _group: 'milestoneList',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addContentRepeater () {
      this.localBrandData.value.splice(this.id + 1, 0, {
        component: cardContentRepeater,
        items: [],
        _group: 'cardContentRepeater',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addColorRatio () {
      this.localBrandData.value.splice(this.id + 1, 0, {
        items: [],
        component: cardColorRatio,
        _group: 'cardColorRatio',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addCardIntro () {
      this.localBrandData.value.splice(this.id + 1, 0, {
        text: '',
        duration: '1',
        priority: 'low',
        component: cardIntro,
        _group: 'cardIntro',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addRecentAssets () {
      this.localBrandData.value.splice(this.id + 1, 0, {
        component: RecentAssets,
        _group: 'recentAssets',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addColorList () {
      this.localBrandData.value.splice(this.id + 1, 0, {
        items: [],
        component: cardColorList,
        _group: 'cardColorList',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addColorGradient () {
      this.localBrandData.value.splice(this.id + 1, 0, {
        items: [],
        component: cardColorGradient,
        _group: 'cardColorGradient',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addWrongUsageRepeater () {
      this.localBrandData.value.splice(this.id + 1, 0, {
        text: '',
        items: [],
        component: cardWrongUsageRepeater,
        _group: 'cardWrongUsageRepeater',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addCardDownloadList () {
      this.localBrandData.value.splice(this.id + 1, 0, {
        items: [],
        component: cardDownloadList,
        _group: 'cardDownloadList',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addUpdateList () {
      this.localBrandData.value.splice(this.id + 1, 0, {
        component: updateList,
        _group: 'updateList',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addCardColorUsage () {
      this.localBrandData.value.splice(this.id + 1, 0, {
        colors: [],
        items: [],
        component: cardColorUsage,
        _group: 'cardColorUsage',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addDashboardButtons () {
      this.localBrandData.value.splice(this.id + 1, 0, {
        colors: [],
        items: [],
        component: dashboardButtons,
        _group: 'dashboardButtons',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addSidebarContent () {
      this.localBrandData.sidebar.splice(this.id + 1, 0, {
        component: cardContent,
        _group: 'cardContent',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addSidebarDownloadFileList () {
      this.localBrandData.sidebar.splice(this.id + 1, 0, {
        items: [],
        component: cardDownloadFileList,
        _group: 'cardDownloadFileList',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addSidebarExampleList () {
      this.localBrandData.sidebar.splice(this.id + 1, 0, {
        items: [],
        component: cardExampleList,
        _group: 'cardExampleList',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addSidebarLiteratureList () {
      this.localBrandData.sidebar.splice(this.id + 1, 0, {
        items: [],
        component: cardLiteratureList,
        _group: 'cardLiteratureList',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    },
    addSidebarRelatedAssets () {
      this.localBrandData.sidebar.splice(this.id + 1, 0, {
        items: [],
        component: CardRelatedAssets,
        _group: 'cardRelatedAssets',
        uid: this.valueNewUid
      })
      this.scrollToTop(this.valueNewUid)
    }
  },
  components: {
    BrandDataItem,
    TabList,
    TabItem
  }
}
</script>
