<template>
  <div class="editor-item__sub-item">
    <div class="editor-item__sub-header">
      <div class="grid-x align-right">
        <div class="cell shrink">
          <span
            class="editor-item__minimize editor-item__button"
            @click="minimize"
          ><i
            :class="[isVisible ? 'icon-chevron-up' : 'icon-chevron-down']"
          ></i
          ></span>
          <delete-card v-on:confirmDelete="deleteItem(index)"></delete-card>
          <dropdown-item
            ref="dropdown"
            :class="'editor-item__settings editor-item__button'"
            :class-name="'custom'"
            :isIcon="false"
            :unscroll="'main'"
            :align="'left'"
            :y="27"
            :x="27"
          >
            <template slot="btn">
              <i class="icon-menu-settings"></i>
            </template>
            <template slot="body">
              <div class="dropdown-edit">
                <card-item>
                  <ab-select
                    :label="$t('builder.milestones_layout')"
                    :options="layoutOptions"
                    v-model="itemData.layout"
                  ></ab-select>
                  <ab-select
                    :label="$t('builder.choose_size')"
                    :options="sizeOptions"
                    v-model="itemData.size"
                  ></ab-select>
                </card-item>
              </div>
            </template>
          </dropdown-item>
          <span class="sub__drag editor-item__button"
          ><i class="icon-drag-and-drop"></i
          ></span>
        </div>
      </div>
    </div>
    <div class="editor-item__sub-content" :class="[isVisible ? '' : 'hide']">
      <div class="grid-x">
        <div class="cell medium-6 br-2">
          <card-item>
            <ab-input-field
              v-model="itemData.year"
              :placeholder="$t('builder.milestone_year')"
              :label="$t('builder.milestone_year')"
            />
            <ab-input-field
              v-model="itemData.title"
              :placeholder="$t('builder.milestone_title')"
              :label="$t('builder.milestone_title')"
            />
            <ab-input-field
              v-model="itemData.description"
              :placeholder="$t('builder.milestone_description')"
              :label="$t('builder.milestone_description')"
            />
          </card-item>
        </div>
        <div class="cell medium-6">
          <card-item>
            <div
              class="dropzone-single-file__container"
            >
              <ab-dropzone
                :singleFileUpload="true"
                type="file"
                :data="itemData"
                :id="index"
              />
            </div>
          </card-item>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'milestoneListItem',
  props: {
    index: Number,
    item: Object
  },
  data () {
    return {
      isVisible: true,
      id: null,
      layoutOptions: [
        { title: this.$t('builder.milestone_layout_left'), value: 'left' },
        { title: this.$t('builder.milestone_layout_right'), value: 'right' }
      ],
      sizeOptions: [
        { title: this.$t('app.extra-small'), value: 'xsmall' },
        { title: this.$t('app.small'), value: 'small' },
        { title: this.$t('app.medium'), value: 'medium' },
        { title: this.$t('app.large'), value: 'large' },
        { title: this.$t('app.extraLarge'), value: 'xlarge' }
      ]
    }
  },
  mounted () {
    this.id = this._uid
  },
  computed: {
    itemData: {
      get () {
        return this.item
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    minimize: function () {
      this.isVisible = !this.isVisible
    },
    deleteItem: function () {
      this.$emit('deleteItem', this.index)
    }
  }
}
</script>

<style lang="scss" scoped>
.dropzone {
  border: dashed 2px $ui-line-color;
  border-radius: $global-radius;

  &:hover {
    border: dashed 2px $black;
    cursor: pointer;
  }
}

</style>
