<template>
  <div>
    <card-item>
      <ab-dropzone
        :fileUpload="true"
        :data="Object(itemData.downloads)"
        :id="index"
        @delete-download="deleteDownload"
      />
    </card-item>
    <hr class="no-margin"/>
    <card-item>
      <p class="text-right">
        <span class="modal__close" @click="$emit('close')">{{
            $t('app.close')
          }}</span>
      </p>
    </card-item>
  </div>
</template>

<script>
export default {
  name: 'MultiFileUploadModal',
  props: {
    item: Object,
    id: Number,
    index: Number
  },
  computed: {
    itemData: {
      get () {
        return this.item
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    deleteDownload: function (subIndex) {
      this.itemData.downloads.splice(subIndex, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  width: 100%;
}

textarea {
  padding: 0;
  height: auto;
  resize: none;
}

hr {
  margin: 0;
  border-color: $ui-line-color;
}

.card {
  overflow: visible;
}

.card__button {
  padding: 0;
  margin: 0;

  i {
    opacity: 1;
  }

  &:hover {
    background: none;
  }

  &::after {
    background: transparent;
  }
}

.editor-card-sort {
  margin: 0 0 10px;
  padding-right: 0;

  li {
    margin-top: 0;
  }
}

.editor-item__sub-item {
  border: 0;
  border-top: 2px solid $ui-line-color;
}
</style>
