<template>
  <div class="editor-item">
    <editor-header
      items="items"
      :title="title"
      :helperReference="contentItem.page_block_id"
      :hasHelper="contentItem.has_helper"
    ></editor-header>
    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <div class="editor-item__sub-container">
        <div class="color-adder">
          <div class="editor-item__sub-content bt-0">
            <div class="grid-x align-right">
              <div class="cell shrink">
                <card-item>
                  <span @click="addColor()" class="card-rules__add-color"
                  ><i class="icon-plus"></i
                  ></span>
                  <span
                    v-for="(item, index) in contentItem.colors"
                    :key="index"
                    class="card-rules__color-container"
                  >
                    <dropdown-item
                      ref="dropdown"
                      :class="'custom-bp'"
                      :class-name="'custom'"
                      :isIcon="false"
                      :unscroll="'main'"
                      :align="'left'"
                      :y="27"
                      :x="27"
                    >
                      <template slot="btn"
                      ><span
                        class="card-rules__color"
                        :style="{ backgroundColor: '#' + item.value }"
                      ></span
                      ></template>
                      <template slot="body">
                        <div class="custom-bp__section">
                          <ab-color-picker
                            :hex.sync="item.hex"
                            :value.sync="item.value"
                          ></ab-color-picker>
                        </div>
                        <hr/>
                        <div class="custom-bp__section">
                          <span
                            @click="removeColor(index)"
                            class="custom-bp__remove"
                          ><i class="icon-icon-trash"></i>
                            {{ $t('app.remove') }}</span
                          >
                        </div>
                      </template>
                    </dropdown-item>
                  </span>
                </card-item>
              </div>
              <div class="cell shrink card-rules__controls"></div>
            </div>
          </div>
        </div>
        <draggable-item
          v-model="contentData.items"
          :group="'subitems' + contentData.id"
          animation="300"
          handle=".sub__drag"
          @start="dragging = true"
          @end="dragging = false"
        >
          <div
            class="editor-item__sub-item"
            v-for="(childItem, index) in contentData.items"
            :key="index"
            :dragStatus="dragging"
          >
            <div class="editor-item__sub-header">
              <div class="grid-x align-right">
                <div class="cell shrink">
                  <delete-card
                    v-on:confirmDelete="deleteItem(index)"
                  ></delete-card>
                  <span class="sub__drag editor-item__button"
                  ><i class="icon-drag-and-drop"></i
                  ></span>
                </div>
              </div>
            </div>

            <div class="editor-item__sub-content">
              <div class="grid-x">
                <div class="cell auto align-left align-self-middle card__section">
                  <h3>
                    <input
                      :placeholder="$t('builder.color_usage_category')"
                      v-model="childItem.title"
                    />
                  </h3>
                </div>
                <div class="cell shrink">
                  <card-item>
                    <span
                      v-for="(color, index) in contentItem.colors"
                      :key="index"
                      class="card-rules__color-container"
                    >
                      <span
                        class="card-rules__color has-border"
                        :style="{ backgroundColor: '#' + color.value }"
                      ></span>
                    </span>
                  </card-item>
                </div>
                <div class="cell shrink card-rules__controls"></div>
              </div>
            </div>

            <draggable-item
              v-model="childItem.types"
              :group="'subitems' + childItem.id + index"
              animation="300"
              handle=".card-rules__controls-drag"
              @start="dragging = true"
              @end="dragging = false"
              :dragStatus="dragging"
            >
              <div
                class="editor-item__sub-content"
                v-for="(type, sub_index) in childItem.types"
                :key="sub_index"
              >
                <div class="grid-x">
                  <div class="cell auto align-self-middle">
                    <card-item>
                      <h3>
                        <input
                          v-model="type.title"
                          :placeholder="$t('app.title')"
                        />
                      </h3>
                    </card-item>
                  </div>
                  <div class="cell shrink">
                    <card-item>
                      <span
                        v-for="(check, sub_index) in type.checks"
                        :key="sub_index"
                        class="card-rules__icon"
                        :class="[check.checkbox == '1' ? 'is-correct' : '']"
                      >
                        <input
                          type="checkbox"
                          v-model="check.checkbox"
                          true-value="1"
                          false-value="0"
                        />
                        <i
                          :class="[
                            check.checkbox == '1' ? 'icon-checkmark' : ''
                          ]"
                        ></i>
                      </span>
                    </card-item>
                  </div>
                  <div class="cell shrink card-rules__controls">
                    <card-item>
                      <span
                        @click="removeRule(index, sub_index)"
                        class="card-rules__controls-button"
                      ><i class="icon-icon-trash"></i
                      ></span>
                      <span
                        class="card-rules__controls-button card-rules__controls-drag"
                      ><i class="icon-drag-and-drop"></i
                      ></span>
                    </card-item>
                  </div>
                </div>
              </div>
              <hr/>
            </draggable-item>
            <add-brand-data-button
              @add-item="addRule(index)"
              :title="$t('builder.add_rule')"
            />
          </div>
        </draggable-item>
        <div v-if="contentItem.items.length" class="spacer--30"></div>
      </div>
      <hr/>
      <add-brand-data-button
        @add-item="addCategory"
        :title="$t('builder.add_category')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'cardColorUsage',
  props: {
    index: Number,
    contentItem: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      id: null,
      isVisible: true,
      subIsVisible: true
    }
  },
  mounted () {
    this.id = this._uid
  },
  methods: {
    removeRule (index, subIndex) {
      this.$modal.show('dialog', {
        title: '',
        text: this.$t('app.remove_item'),
        buttons: [
          {
            title: this.$t('app.cancel'),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('app.remove_item'),
            default: true,
            handler: () => {
              this.contentData.items[index].types.splice(subIndex, 1)
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    removeColor (index) {
      this.$modal.show('dialog', {
        title: '',
        text: this.$t('app.remove_item'),
        buttons: [
          {
            title: this.$t('app.cancel'),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('app.remove_item'),
            default: true,
            handler: () => {
              this.contentData.colors.splice(index, 1)
              this.contentData.items.forEach(item => {
                item.types.forEach(subItem => {
                  subItem.checks.splice(index, 1)
                })
              })
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    addCategory () {
      this.contentData.items.push({
        title: '',
        types: []
      })
    },
    addRule (index) {
      const colorLength = []
      this.contentData.colors.forEach(item => {
        item.items = this.contentData.colors.length
        colorLength.push({
          checkbox: null
        })
      })
      this.contentData.items[index].types.push({
        title: '',
        checks: colorLength
      })
    },
    addColor () {
      this.contentData.items.forEach(item => {
        item.types.forEach(subItem => {
          subItem.checks.push({
            checkbox: null
          })
        })
      })
      this.contentData.colors.push({
        value: '000000'
      })
    },
    deleteItem: function (index) {
      this.contentData.items.splice(index, 1)
    }
  },
  computed: {
    title: function () {
      if (this.contentItem._title) {
        return this.contentItem._title
      }
      return this.$t('builder.color_usage')
    },
    dragging: {
      get () {
        return this.dragStatus
      },
      set (val) {
        this.$emit('update:dragStatus', val)
      }
    },
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
hr {
  margin: 0;
  border-color: $ui-line-color;
}

.color-adder {
  background: $light-gray-bg;
  margin-bottom: calc(10px);
  border-radius: $global-radius;

  .card {
    background: none;
  }
}

.card {
  overflow: visible;
}

.color-picker {
  input {
    margin: 0;
    padding: 0;
    visibility: hidden;
    height: 25px;
  }
}

h3 {
  input {
    color: $gray;
    font-size: 1rem;
    font-weight: 500;
    border: none;
    background: none;
    width: 100%;
  }
}

.overflow-visible {
  overflow: visible;
}

.card-rules {
  &__controls-button {
    color: $gray;

    .icon-icon-trash {
      &:hover {
        color: $delete;
      }
    }

    .icon-drag-and-drop {
      &:hover {
        color: $black;
      }
    }
  }
}
</style>
