<template>
  <div class="editor-item">
    <editor-header
      :title="$t('builder.latest_updates')"
      :hasHelper="contentItem.has_helper"
    />
  </div>
</template>

<script>
export default {
  name: 'UpdateList',
  props: ['contentItem'],
  data () {
    return {
      isVisible: true
    }
  }
}
</script>
