<template>
  <div
    class="cell shrink color-ratio__item"
    :style="{ width: itemData.percentage + '%' }"
  >
    <ul class="editor-card-sort drag-ratio-label">
      <li class="float-left percentage-input">
        <ab-input-field
          input-prefix="%"
          v-model="itemData.percentage"
          :max="100"
          type="number"
        />
      </li>
      <delete-card v-on:confirmDelete="deleteItem(index)"></delete-card>
      <li class="editor-card-sort__item">
        <span class="editor-settings__button sub__drag"
        ><i class="icon-drag-and-drop"
        /></span>
      </li>
    </ul>

    <ab-color-picker
      inputPrefix="#"
      :hex.sync="hex"
      :value.sync="itemData.title"
      class="card-ratio__color has-border minus-border"
      :style="{ backgroundColor: '#' + itemData.title }"
    />

  </div>
</template>

<script>
export default {
  name: 'ColorRatioItem',
  props: {
    index: Number,
    item: Object
  },
  data () {
    return {
      hex: this.item.title
    }
  },
  computed: {
    itemData: {
      get () {
        return this.item
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    deleteItem: function () {
      this.$emit('deleteItem', this.index)
    }
  }
}
</script>

<style scoped lang="scss">
.minus-border {
  ab-color-picker {
    border: none !important;
  }
}

.percentage-input {
  width: 80px;
  max-height: 25px;
  margin: 0px 0px 25px 0px;
  padding: 0px !important;
}

.ab-color-picker {
  border: none;
}

.drag-ratio-label {
  padding: 5px 0px 5px 5px;
  background: $light-gray-bg;
  border: solid 2px $ui-line-color;
  border-radius: $global-radius $global-radius 0 0;
}

.editor-card-sort__item {
  margin: 13px 17px 0px 0px;
}

.editor-item__button {
  margin-left: 0;
}

.card-ratio {
  &__color {
    border-radius: 0 0 $global-radius $global-radius;
    margin-bottom: 0;
  }
}
</style>
