<template>
  <div class="editor-item">
    <editor-header
      :title="title"
      :helperReference="contentItem.page_block_id"
      :hasHelper="contentItem.has_helper"
    />
    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <div class="card-gradients">
        <card-item>
          <content-editor
            :disabled="dragging"
            :contentData.sync="contentData.text"
          ></content-editor>
        </card-item>
        <draggable-item
          v-model="contentData.items"
          :group="'subitems' + contentItem.id"
          animation="300"
          handle=".sub__drag"
          @start="dragging = true"
          @end="dragging = false"
        >
          <div
            v-for="(item, index) in contentItem.items"
            :key="index"
            :dragStatus="dragging"
          >
            <hr/>
            <card-item>
              <ul class="editor-card-sort">
                <delete-card
                  v-on:confirmDelete="deleteItem(index)"
                ></delete-card>
                <li class="editor-card-sort__item">
                  <span class="editor-settings__button sub__drag"
                  ><i class="icon-drag-and-drop"></i
                  ></span>
                </li>
              </ul>
              <div
                class="card-gradients__color has-border"
                :style="{ background: gradient(item) }"
              ></div>
              <div class="grid-x align-justify">
                <div
                  v-for="(color, sub_index) in item.colors"
                  :key="sub_index"
                  class="cell shrink"
                >
                  <ab-color-picker
                    :label="$t('builder.color')"
                    :hex.sync="color.hex"
                    :value.sync="color.value"
                  ></ab-color-picker>
                  <ab-input-field
                    :label="$t('app.title')"
                    v-model="color.title"
                  ></ab-input-field>
                </div>
              </div>
            </card-item>
          </div>
        </draggable-item>
        <hr/>
        <add-brand-data-button
          @add-item="addCard"
          :title="$t('builder.add_gradient')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cardColorGradient',
  props: {
    index: Number,
    contentItem: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      isVisible: true
    }
  },
  methods: {
    gradient: function (item) {
      let colors = 'linear-gradient(to right'
      item.colors.forEach(function (el) {
        colors += ',#' + el.value
      })
      colors += ')'
      return colors
    },
    addCard () {
      this.contentData.items.push({
        title: '',
        colors: [
          {
            title: '',
            value: '000000'
          },
          {
            title: '',
            value: 'FFFFFF'
          }
        ]
      })
    },
    deleteItem: function (index) {
      this.contentData.items.splice(index, 1)
    }
  },
  computed: {
    title: function () {
      if (this.contentItem._title) {
        return this.contentItem._title
      }
      return this.$t('builder.color_gradient')
    },
    dragging: {
      get () {
        return this.dragStatus
      },
      set (val) {
        this.$emit('update:dragStatus', val)
      }
    },
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  width: 100%;
}

textarea {
  padding: 0;
  height: auto;
  resize: none;
}

hr {
  margin: 0;
  border-color: $ui-line-color;
}

.card {
  overflow: visible;
}

.editor-card-sort {
  margin: 0 0 10px;
  padding-right: 0;

  li {
    margin-top: 0;
  }
}
</style>
