<template>
  <draggable-item
    v-model="content"
    group="value"
    animation="300"
    handle=".editor-item__drag"
    v-bind="dragOptions"
    @start="drag = true"
    @end="drag = false"
    :class="{ 'is-dragging': drag }"
  >
    <div
      v-for="(contentItem, index) in content"
      style="position: relative;"
      :id="'page-block--' + contentItem.uid"
      :key="contentItem.uid"
    >
      <component
        :key="index"
        :dragStatus.sync="drag"
        :dropzoneReady="dropzoneReady"
        v-bind:is="getComponent(contentItem._group)"
        @remove="$emit('remove', index)"
        :contentItem.sync="content[index]"
        :isMainContent="isMainContent"
        class="editor__container"
      ></component>
      <add-brand-component
        @add-item="$emit('add-item', index, isMainContent)"
      />
    </div>
  </draggable-item>
</template>

<script>
import cardColorGradient from '../../brand/components/edit/content/CardColorGradient'
import cardColorList from '../../brand/components/edit/content/CardColorList'
import cardColorRatio from '../../brand/components/edit/content/CardColorRatio'
import cardColorUsage from '../../brand/components/edit/content/CardColorUsage'
import cardContent from '../../brand/components/edit/content/CardContent'
import cardContentRepeater from '../../brand/components/edit/content/CardContentRepeater'
import cardDownloadList from '../../brand/components/edit/content/CardDownloadList'
import cardImageList from '../../brand/components/edit/content/CardImageList'
import cardIntro from '../../brand/components/edit/content/CardIntro'
import cardList from '../../brand/components/edit/content/CardList'
import cardWrongUsageRepeater from '../../brand/components/edit/content/CardWrongUsageRepeater'
import chapterTitle from '../../brand/components/edit/content/ChapterTitle'
import pageBanner from '../../brand/components/edit/content/PageBanner'
import dashboardButtons from '../../brand/components/edit/content/DashboardButtons'
import recentAssets from '../../brand/components/edit/content/RecentAssets'
import updateList from '../../brand/components/edit/content/UpdateList'
import cardDownloadFileList from '../../brand/components/edit/sidebar/CardDownloadFileList'
import cardExampleList from '../../brand/components/edit/sidebar/CardExampleList'
import cardLiteratureList from '../../brand/components/edit/sidebar/CardLiteratureList'
import cardRelatedAssets from '../../brand/components/edit/sidebar/CardRelatedAssets'
import splitBanner from '../../brand/components/edit/content/SplitBanner'
import milestoneList from '../../brand/components/edit/content/MilestoneList'

export default {
  name: 'EditContentRepeater',
  props: {
    dragOptions: Object,
    contentItems: Array,
    dropzoneReady: Boolean,
    isMainContent: Boolean
  },
  data () {
    return {
      drag: false
    }
  },
  methods: {
    getComponent (groupName) {
      if (groupName === 'newsList') return 'updateList'
      return groupName
    }
  },
  computed: {
    content: {
      get () {
        return this.contentItems
      },
      set (val) {
        this.$emit('update:contentItems', val)
      }
    }
  },
  components: {
    cardColorGradient,
    cardColorList,
    cardColorRatio,
    cardColorUsage,
    cardContent,
    cardContentRepeater,
    cardDownloadList,
    cardImageList,
    cardIntro,
    cardList,
    cardWrongUsageRepeater,
    chapterTitle,
    pageBanner,
    splitBanner,
    milestoneList,
    recentAssets,
    updateList,
    cardDownloadFileList,
    cardExampleList,
    cardLiteratureList,
    cardRelatedAssets,
    dashboardButtons
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/scss/init/_init";

.button__explanation {
  position: absolute;
  top: -5px;
  left: -5px;
  background: white;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  border: 2px solid $ui-line-color;
  text-align: center;
  cursor: help;

  &::before {
    content: "?";
    font-weight: 500;
    position: relative;
    top: 1px;
    color: $light-gray;
  }
}
</style>
