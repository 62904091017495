<template>
  <div class="editor-item">
    <editor-header
      :title="title"
      :helperReference="contentData.page_block_id"
      :hasHelper="contentData.has_helper"
    />

    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <div class="card__section">
        <draggable-item
          class="grid-x grid-margin-x card-list"
          v-model="contentData.items"
          :group="'subitems' + contentData.id"
          animation="300"
          handle=".sub__drag"
          @start="dragging = true"
          @end="dragFinished"
        >
          <div
            class="cell medium-4 card"
            v-for="(item, index) in contentData.items"
            :key="index"
            :dragStatus="dragging"
          >
            <ul class="editor-card-sort">
              <delete-card v-on:confirmDelete="deleteItem(index)"/>
              <li class="editor-card-sort__item">
                <span class="editor-settings__button sub__drag"
                ><i class="icon-drag-and-drop"
                /></span>
              </li>
            </ul>
            <card-item>
              <ab-input-field
                :label="$t('app.title')"
                v-model="item.title"
                type="text"
              />
            </card-item>
            <card-item>
              <div class="vue-dropzone-single">
                <ab-dropzone :data="item" :id="index" :key="componentKey"/>
              </div>
            </card-item>
          </div>
        </draggable-item>
      </div>
      <hr/>
      <add-brand-data-button
        @add-item="addCard"
        :title="$t('builder.add_image')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'cardImageList',
  props: {
    index: Number,
    contentItem: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      componentKey: 0,
      isVisible: true
    }
  },
  methods: {
    dragFinished () {
      this.dragging = false
      this.componentKey += 1
    },
    addCard () {
      this.contentData.items.push({
        title: '',
        image: ''
      })
    },
    deleteItem: function (index) {
      this.contentData.items.splice(index, 1)
    }
  },
  computed: {
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    title: function () {
      if (this.contentData._title) {
        return this.contentData._title
      }
      return this.$t('builder.images')
    },
    dragging: {
      get () {
        return this.dragStatus
      },
      set (val) {
        this.$emit('update:dragStatus', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
  padding: 0;
  height: auto;
  resize: none;
}

input {
  width: 100%;
}

.card {
  border: 1px solid $ui-line-color;
}
</style>
