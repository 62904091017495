<template>
  <div>
    <div :class="tabHeaderContainer">
      <ul :class="tabItems">
        <li
          v-for="(tab, key) in tabs"
          :key="key"
          :class="[{ 'is-active': tab.isActive }, tabItem]"
          @click="selectTab(tab)"
        >
          {{ tab.name }}
        </li>
      </ul>
    </div>

    <div :class="tabContentContainer">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabList',
  props: {
    tabItems: String,
    tabItem: String,
    tabContentContainer: String,
    tabHeaderContainer: String
  },
  data () {
    return { tabs: [] }
  },
  created () {
    this.tabs = this.$children
  },
  methods: {
    selectTab (selectedTab) {
      this.tabs.forEach(tab => {
        tab.isActive = tab.name === selectedTab.name
      })
    }
  }
}
</script>

<style scoped></style>
