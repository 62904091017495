<template>
  <div class="editor-item">
    <editor-header
      :title="title"
      :helperReference="contentData.page_block_id"
      :hasHelper="contentData.has_helper"
    />
    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <card-item>
        <content-editor
          :disabled="dragging"
          :contentData.sync="contentData.text"
        />
        <div
          v-if="contentData.items !== undefined && contentData.items.length > 0"
          class="spacer--30"
        ></div>
        <draggable-item
          class="grid-x grid-margin-x grid-margin-y large-up-3"
          v-model="contentData.items"
          :group="'subitems' + contentData.id"
          animation="300"
          handle=".sub__drag"
          @start="dragging = true"
          @end="dragFinished"
        >
          <div
            class="cell has-border"
            v-for="(item, index) in contentData.items"
            :key="index"
            :dragStatus="dragging"
          >
            <ul class="editor-card-sort">
              <delete-card v-on:confirmDelete="deleteItem(index)"></delete-card>
              <li class="editor-card-sort__item editor-item__button">
                <span class="sub__drag"><i class="icon-drag-and-drop"/></span>
              </li>
            </ul>
            <card-item>
              <div class="vue-dropzone-single">
                <ab-dropzone :data="item" :id="index" :key="componentKey"/>
              </div>
              <div class="spacer--30"></div>
              <ab-input-field :label="$t('app.rule')" v-model="item.title"/>
            </card-item>
          </div>
        </draggable-item>
      </card-item>
      <hr/>
      <add-brand-data-button
        @add-item="addItem"
        :title="$t('builder.add_wrong_examples')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'cardWrongUsageRepeater',
  props: {
    index: Number,
    contentItem: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      id: null,
      componentKey: 0,
      isVisible: true
    }
  },
  methods: {
    dragFinished () {
      this.dragging = false
      this.componentKey += 1
    },
    addItem () {
      this.contentData.items.push({
        image: '',
        title: ''
      })
    },
    deleteItem: function (index) {
      this.contentData.items.splice(index, 1)
    }
  },
  computed: {
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    title: function () {
      if (this.contentData._title) {
        return this.contentData._title
      }
      return this.$t('builder.wrong_examples')
    },
    dragging: {
      get () {
        return this.dragStatus
      },
      set (val) {
        this.$emit('update:dragStatus', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
hr {
  margin: 0;
  border-color: $ui-line-color;
}

.card {
  overflow: visible;
}

.wrong-usage {
  .editor-card-sort {
    padding-right: 0 !important;
  }

  > div > li {
    padding: 0 10px 10px 1.875rem !important;

    &::before {
      top: 48px;
      bottom: 14px;
      left: 6px;
    }

    .removeItem {
      position: absolute;
      right: 0px;
      top: 4px;
      left: inherit;
    }
  }
}
</style>
