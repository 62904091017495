<template>
  <div class="editor-item__sub-item">
    <div class="editor-item__sub-header">
      <div class="grid-x align-right">
        <div class="cell shrink">
          <span
            class="editor-item__minimize editor-item__button"
            @click="minimize"
          ><i
            :class="[isVisible ? 'icon-chevron-up' : 'icon-chevron-down']"
          ></i
          ></span>
          <dropdown-item
            ref="dropdown"
            :class="'editor-item__settings editor-item__button'"
            :class-name="'custom'"
            :isIcon="false"
            :unscroll="'main'"
            :align="'left'"
            :y="27"
            :x="27"
          >
            <template slot="btn">
              <i class="icon-menu-settings"></i>
            </template>
            <template slot="body">
              <div class="dropdown-edit">
                <card-item>
                  <ab-select
                    :label="$t('builder.choose_column_size')"
                    :options="columnSize"
                    v-model="itemData.columns"
                  ></ab-select>
                </card-item>
              </div>
            </template>
          </dropdown-item>
          <delete-card v-on:confirmDelete="deleteItem"></delete-card>
          <span class="sub__drag editor-item__button"
          ><i class="icon-drag-and-drop"></i
          ></span>
        </div>
      </div>
    </div>
    <div class="editor-item__sub-content" :class="[isVisible ? '' : 'hide']">
      <div class="grid-x">
        <div class="cell br-2" :class="'medium-' + columnWidth(itemData.columns)">
          <div class="card__section">
            <content-editor
              :disabled="dragStatus"
              :contentData.sync="itemData.text"
            ></content-editor>
          </div>
        </div>
        <div class="cell flex-stretch auto">
          <card-item>
            <div class="toggle-button">
              <div class="grid-x grid-margin-x align-middle">
                <div class="cell shrink">
                  <p class="mb-0">{{ $t('builder.image_padding') }}:</p>
                </div>
                <div class="cell auto ml-0 pt-6">
                  <switches v-model="itemData.imagePadding"></switches>
                </div>
              </div>
            </div>
          </card-item>
          <div
            class="flex-stretch editor-item__padding"
            :class="itemData.imagePadding != 0 ? 'has-padding' : ''"
            style="position: relative;"
          >
            <div class="vue-dropzone-single">
              <ab-dropzone :data="itemData" :id="index" :relation="'page'"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Switches from 'vue-switches'

export default {
  name: 'TextImageCard',
  props: {
    index: Number,
    item: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      isVisible: true,
      columnSize: [
        { title: '1-1', value: '1-1' },
        { title: '1-2', value: '1-2' },
        { title: '2-1', value: '2-1' }
      ],
      hideSettings: undefined
    }
  },
  computed: {
    itemData: {
      get () {
        return this.item
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    minimize: function () {
      this.isVisible = !this.isVisible
    },
    deleteItem: function () {
      this.$emit('deleteItem', this.index)
    },
    columnWidth (item) {
      if (item === '1-2') {
        return '4'
      } else if (item === '2-1') {
        return '8'
      } else {
        return '6'
      }
    }
  },
  components: {
    Switches
  }
}
</script>

<style lang="scss" scoped>
.pt-6 {
  padding-top: 6px;
}
</style>
