<template>
  <div class="editor-item">
    <editor-header
      :title="title"
      :helperReference="contentData.page_block_id"
      :hasHelper="contentData.has_helper"
    />
    <div class="editor-item__content" :class="[isVisible ? '' : 'hide']">
      <card-item v-if="contentData.items.length">
        <draggable-item
          class="grid-x grid-margin-x card-list"
          v-model="contentData.items"
          :group="'subitems' + contentData.id"
          animation="300"
          handle=".sub__drag"
          @start="dragging = true"
          @end="dragging = false"
        >
          <div
            class="cell medium-4 card has-border"
            v-for="(item, index) in contentData.items"
            :key="item.uid"
            :dragStatus="dragging"
          >
            <ul class="editor-card-sort">
              <delete-card v-on:confirmDelete="deleteItem(index)"/>
              <li class="editor-card-sort__item">
                <span class="editor-settings__button sub__drag"
                ><i class="icon-drag-and-drop"
                /></span>
              </li>
            </ul>
            <div class="card__title">
              <div class="grid-x">
                <div class="small-12 cell">
                  <ab-input-field
                    :label="$t('app.title')"
                    v-model="item.title"
                    type="text"
                  />
                </div>
              </div>
              <div class="spacer--20"></div>
            </div>
            <hr/>
            <div class="card__section">
              <div class="grid-x">
                <div class="small-12 cell">
                  <p>Upload bestanden</p>
                  <span class="button--icon-upload" @click="show(item, id, index)">
                    <i class="icon-upload-total float-left"></i>
                    <div class="upload-label float-left">{{ $t('builder.logo_upload_files') }}</div>
                  </span>
                </div>
              </div>
            </div>
            <hr/>
            <card-item>
              <p>Upload een preview (SVG, PNG of JPG)</p>
              <div class="vue-dropzone-single">
                <ab-dropzone :data.sync="contentData.items[index]" :id="index"/>
              </div>
            </card-item>
          </div>
        </draggable-item>
      </card-item>
      <hr v-if="contentData.items.length > 0"/>
      <add-brand-data-button
        @add-item="addCard"
        :title="$t('builder.add_download')"
      />
    </div>
  </div>
</template>

<script>
import multiFileUploadFileUpload from '../elements/modal/MultiFileUpload'

export default {
  name: 'cardDownloadList',
  props: {
    index: Number,
    contentItem: Object,
    dragStatus: Boolean
  },
  data () {
    return {
      id: null,
      isVisible: true
    }
  },
  mounted () {
    this.id = this._uid
    for (let i = 0; i < this.contentData.items.length; i++) {
      if (!this.contentData.items[i].uid) {
        this.contentData.items[i].uid = this.contentData.items.length + i
      }
    }
  },
  methods: {
    addCard () {
      this.contentData.items.push({
        uid: this.contentData.items.length,
        title: '',
        image: '',
        downloads: []
      })
    },
    show (item, id, index) {
      this.$modal.show(
        multiFileUploadFileUpload,
        {
          item: item,
          id: id,
          index: index
        },
        {
          draggable: false,
          scrollable: true,
          height: 'auto',
          width: '600px'
        }
      )
    },
    deleteItem: function (index) {
      this.componentKey += 1
      this.contentData.items.splice(index, 1)
    }
  },
  computed: {
    contentData: {
      get () {
        return this.contentItem
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    title: function () {
      if (this.contentData._title) {
        return this.contentData._title
      }
      return this.$t('builder.downloads')
    },
    dragging: {
      get () {
        return this.dragStatus
      },
      set (val) {
        this.$emit('update:dragStatus', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
textarea {
  padding: 0;
  height: auto;
  resize: none;
}

input {
  width: 100%;
}

.editor-card-sort {
  padding: 12px 30px 0px 30px;
}

.button--icon-upload {
  border: dashed 2px $ui-line-color;
  border-radius: $global-radius;
  background: $white;
  cursor: pointer;
  margin: calc(10px) 0 0 0;
  width: 100%;

  i {
    height: 50px;
    padding: calc(16px) calc(18px);
    display: inline-block;
    background: none;
  }

  .upload-label {
    padding: calc(18px) calc(3px);
  }

  &:hover {
    border: dashed 2px $black;
    background: $light-gray-bg;
  }
}
</style>
